import React, { useState, useMemo, useEffect, useCallback } from 'react'
import PropTypes, { element } from "prop-types";
import Select from "react-select";
import { Col, Form, Input, Label, Modal, Row, Table } from 'reactstrap';
import { get, post, postFormData, put } from 'helpers/api_helper';
import { showToast } from 'helpers/util';
import Upload from 'components/Common/Upload';
import { useForm } from "react-hook-form";
import { createBanner } from 'helpers/Api/api';
import { useMutation } from '@tanstack/react-query';

const CreateUpdateBanner = ({ data, toggle, modal, handleCreateModal, refresh }) => {
  const [errors, setErrors] = useState([])
  const { register, handleSubmit, setValue, formState: { formError } } = useForm();
  const { mutate, isLoading } = useMutation(['createBanner'], (val) => submitForm(val), {
    onSuccess: async (data) => {
      if (data.status) {
        showToast(data.message, 'success')
        toggle()
        refresh()
        return;
      }else{
        setErrors(data.errors);
        showToast(data.message, 'error')
      }
    },
  });

  // useEffect(() => {
  //   if (data) {
  //     const fields = ['category_id', 'subcategory_id', 'name', 'type', 'description', 'price', 'purchase_price'];
  //     fields.forEach(field => setValue(field, data[field]));
  //   }
  // }, [data])

  const submitForm = async (value) => {
    console.log(value)
    let url = 'banners';
    var formData = new FormData();
    formData.append('image', value.file[0])

    var res = await postFormData(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return res;
  }

  const submit = handleSubmit(values => mutate(values));

  return (
    <Modal
      toggle={() => handleCreateModal()}
      isOpen={modal}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? 'Update Banner' : 'Create Banner'}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form onSubmit={submit}>
          <Row>
            <Col>
              <Upload setFile={(val) => setValue('file', val)} max={1} />
            </Col>
          </Row>

          <div className="d-md-flex justify-content-md-end">
            <button type="submit" className="btn btn-primary w-md" disabled={isLoading}>
              {isLoading ? (
                <>
                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                  Loading
                </>
              ) : (
                <span>Submit</span>
              )}
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

CreateUpdateBanner.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.boolean,
  toggle: PropTypes.func,
  refresh: PropTypes.func,
  handleCreateModal: PropTypes.func,
};

export default CreateUpdateBanner