import Editor from 'components/Common/Editor';
import React from 'react'

const FieldType = ({data, handleChange}) => {
  console.log(data)
  return (
    <>
      {data.type == 'Editor' && (
        <Editor handleChange={(val) => handleChange('value', val)} defaultValue={data?.value} />
      )}
    </>
  )
}


export default React.memo(FieldType);
