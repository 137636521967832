import React, { useState, useMemo, useEffect, useCallback } from 'react'
import PropTypes from "prop-types";
import Select from "react-select";
import { Col, Form, Input, Label, Modal, Row, Table } from 'reactstrap';
import { get, post, postFormData, put } from 'helpers/api_helper';
import { showToast } from 'helpers/util';
import Upload from 'components/Common/Upload';

const CreateJob = ({ data, toggle, modal, handleCreateModal, refresh }) => {
  const [loading, setLoading] = useState(false)
  const [categoryData, setCategoryData] = useState([])
  const [subcategoryData, setSubcategoryData] = useState([])
  const [categoryId, setService] = useState('')
  const [subcategoryId, setSubcategoryId] = useState([])
  const [workType, setWorkType] = useState('')
  const [priority, setPriority] = useState('')
  const [date, setDate] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [postcode, setPostcode] = useState('')
  const [status, setStatus] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [file, setFile] = useState(null)
  const [errors, setErrors] = useState([])

  const getCategory = useCallback((val) => {
    setLoading(true)
    get('/select-categories').then(res => {
      let categories = [];
      res.map(item => categories.push({ label: item.name, value: item.id }));
      setCategoryData(categories)
    })
    setLoading(false)
  }, [])

  useEffect(() => {
    setSubcategoryId('')
    setLoading(true)
    get('/select-subcategories', {
      params: {
        category_id: categoryId
      }
    }).then(res => {
      let subcategories = [];
      res.map(item => subcategories.push({ label: item.name, value: item.id }));
      setSubcategoryData(subcategories)
      setLoading(false)
    })
  }, [categoryId]);

  useEffect(() => {
    // if (!data) {
    //   setAppointment('')
    //   setPatient('')
    //   setNotes('')
    //   setProduct([{ id: '', name: '', price: '', tax_type: '', sku: '' }])
    // } else {
    //   setAppointment(data.appointment_id === 0 ? '0' : data.appointment_id)
    //   setPatient(data.user_id)
    //   setNotes(data.notes)
    //   let products = [];
    //   data.invoice_detail.map(item => products.push({
    //     id: item.product.id,
    //     sku: item.product.sku,
    //     name: item.product.name,
    //     price: item.product.price,
    //     tax_type: item.product.tax_type,
    //   }))
    //   setProduct(products)
    // }
    if (!categoryData.length) {
      getCategory();
    }
  }, [data, file])

  const handleSubmit = async () => {
    try {
      setLoading(true);
      var formData = new FormData();
      formData.set('category_id', categoryId ? categoryId : '');
      formData.set('subcategory_id', JSON.stringify(subcategoryId) ? JSON.stringify(subcategoryId) : '');
      formData.set('work_type', workType ? workType : '');
      formData.set('priority', priority ? priority : '');
      formData.set('date', date ? date : '');
      formData.set('name', name ? name : '');
      formData.set('email', email ? email : '');
      formData.set('phone', phone ? phone : '');
      formData.set('address', address ? address : '');
      formData.set('postcode', postcode ? postcode : '');
      formData.set('subject', subject ? subject : '');
      formData.set('status', status ? status : '');
      formData.set('message', message ? message : '');
      for (let i = 0; i < file.length; i++) {
        formData.append('file[]', file[i])
      }

      var res = await postFormData(`work-orders`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      showToast(res.message, !res.status && 'error')
      if (!res.status) {
        return setErrors(res.errors)
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
    // setLoading(true);
    // if (data) {
    //   var res = await put(`invoice/${data.id}`, {
    //     notes,
    //   });
    // } else {
    //   var res = await post('invoice', {
    //     notes,
    //   });
    // }
    // showToast(res.message, !res.status && 'error')
    // setLoading(false)
    // if (!res.status) {
    //   setErrors(res.errors)
    //   return;
    // }
    toggle()
    refresh()
  }


  return (
    <Modal
      size="lg"
      toggle={() => handleCreateModal()}
      isOpen={modal}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? 'Update Work Order' : 'Create Work Order'}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form onSubmit={(e) => {
          e.preventDefault()
          handleSubmit()
        }}>
          <Row>
            <Col md={6} className="mb-3">
              <Label htmlFor="formrow-password-Input">Category</Label>
              <Select
                options={categoryData}
                isLoading={loading}
                classNamePrefix="select2-selection"
                onChange={(e) => setService(e.value)}
              />
              {errors.category_id && <span className="form-text text-danger">{errors.category_id[0]}</span>}
            </Col>
            <Col md={6} className="mb-3">
              <Label htmlFor="formrow-password-Input">Service Type</Label>
              <Select
                options={subcategoryData}
                isLoading={loading}
                classNamePrefix="select2-selection"
                onChange={(e, no) => {
                  let value = Array.from(e, option => option.value);
                  setSubcategoryId(value);
                }}
                isMulti
              />
              {errors.service_type && <span className="form-text text-danger">{errors.service_type[0]}</span>}
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-3">
              <Label htmlFor="formrow-password-Input">Work Type</Label>
              <Select
                options={[
                  { label: 'Commercial', value: 'Commercial' },
                  { label: 'Residential', value: 'Residential' },
                ]}
                isLoading={loading}
                classNamePrefix="select2-selection"
                onChange={(e) => setWorkType(e.value)}
              />
              {errors.service_type && <span className="form-text text-danger">{errors.service_type[0]}</span>}
            </Col>
            <Col md={4} className="mb-3">
              <Label htmlFor="formrow-password-Input">Priority</Label>
              <Select
                options={[
                  {label: 'Flexible', value: 'Flexible'},
                  {label: 'Can Wait', value: 'Can Wait'},
                  {label: 'Need It Today', value: 'Need It Today'},
                  {label: 'Need It Tomorrow', value: 'Need It Tomorrow'},
                ]}
                isLoading={loading}
                classNamePrefix="select2-selection"
                onChange={(e) => setPriority(e.value)}
              />
              {errors.priority && <span className="form-text text-danger">{errors.priority[0]}</span>}
            </Col>
            <Col md={4} className="mb-3">
              <Label htmlFor="date">Date</Label>
              <Input
                type="datetime-local"
                className="form-control"
                placeholder="Date"
                id="date"
                onInput={(e) => setDate(e.target.value)}
              />
              {errors.date && <span className="form-text text-danger">{errors.date[0]}</span>}
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-3">
              <Label htmlFor="name">Name</Label>
              <Input
                type="text"
                className="form-control"
                placeholder="Full Name"
                id="name"
                onInput={(e) => setName(e.target.value)}
              />
              {errors.name && <span className="form-text text-danger">{errors.name[0]}</span>}
            </Col>
            <Col md={4} className="mb-3">
              <Label htmlFor="email">Email</Label>
              <Input
                type="email"
                className="form-control"
                placeholder="Email"
                id="email"
                onInput={(e) => setEmail(e.target.value)}
              />
              {errors.email && <span className="form-text text-danger">{errors.email[0]}</span>}
            </Col>
            <Col md={4} className="mb-3">
              <Label htmlFor="phone">Phone</Label>
              <Input
                type="text"
                className="form-control"
                placeholder="Phone"
                id="phone"
                onInput={(e) => setPhone(e.target.value)}
              />
              {errors.phone && <span className="form-text text-danger">{errors.phone[0]}</span>}
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Label htmlFor="address">Address</Label>
              <Input
                type="text"
                className="form-control"
                placeholder="Address"
                id="address"
                onInput={(e) => setAddress(e.target.value)}
              />
              {errors.address && <span className="form-text text-danger">{errors.address[0]}</span>}
            </Col>
            <Col md={2} className="mb-3">
              <Label htmlFor="postcode">Post Code</Label>
              <Input
                type="postcode"
                className="form-control"
                placeholder="Post Code"
                id="postcode"
                onInput={(e) => setPostcode(e.target.value)}
              />
              {errors.postcode && <span className="form-text text-danger">{errors.postcode[0]}</span>}
            </Col>
            <Col md={4} className="mb-3">
              <Label htmlFor="postcode">Status</Label>
              <Select
                options={[
                  {label: 'Pending', value: 'Pending'},
                  {label: 'Processing', value: 'Processing'},
                  {label: 'Completed', value: 'Completed'},
                ]}
                isLoading={loading}
                classNamePrefix="select2-selection"
                onChange={(e) => setStatus(e.value)}
              />
              {errors.status && <span className="form-text text-danger">{errors.status[0]}</span>}
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <Label htmlFor="subject">Subject</Label>
              <Input
                type="text"
                className="form-control"
                placeholder="Subject"
                id="subject"
                onInput={(e) => setSubject(e.target.value)}
              />
              {errors.subject && <span className="form-text text-danger">{errors.subject[0]}</span>}
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <Label htmlFor="message">Message</Label>
              <textarea
                type="text"
                className="form-control"
                placeholder="Message"
                id="message"
                rows={5}
                onInput={(e) => setMessage(e.target.value)}
              />
              {errors.message && <span className="form-text text-danger">{errors.message[0]}</span>}
            </Col>
          </Row>
          <Row>
            <Col>
              <Upload setFile={setFile} />
            </Col>
          </Row>

          <div className="d-md-flex justify-content-md-end">
            <button type="submit" className="btn btn-primary w-md" disabled={loading}>
              {loading ? (
                <>
                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                  Loading
                </>
              ) : (
                <span>Submit</span>
              )}
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

CreateJob.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.boolean,
  toggle: PropTypes.func,
  refresh: PropTypes.func,
  handleCreateModal: PropTypes.func,
};

export default CreateJob