import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from "prop-types";
import { Col, Form, Input, Label, Modal, Row, Table } from 'reactstrap';
import { get, post, postFormData, put } from 'helpers/api_helper';
import { showToast } from 'helpers/util';
import Editor from 'components/Common/Editor';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';

const TicketReply = ({ data, toggle, modal, handleCreateModal, refresh }) => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const { register, handleSubmit, setValue, getValues, watch, formState: { formErrors }, reset } = useForm();

  useEffect(() => {
    if (data) {
      const fields = ['id', 'title', 'short_description', 'content', 'status'];
      fields.forEach(field => setValue(field, data[field]));
    }
  }, [data])

  const submitForm = async (value) => {
    var formData = new FormData();
    formData.set('comment', value.comment);
    formData.set('image', value.image[0]);
    if (data) {
      formData.set('_method', 'put');
      var res = await postFormData(`tickets/${data.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
    } else {
      var res = await postFormData(`tickets`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
    }
    return res;
  }

  const { mutate, isLoading } = useMutation(['tickets'], submitForm, {
    onSuccess: data => {
      if (data.status) {
        showToast(data.message, 'success')
        toggle()
      } else {
        setErrors(data.errors);
      }
      refresh()
    },
    onError: error => {
      console.log('error', error)
      showToast(error, 'error')
    },
  });

  const submit = handleSubmit(values => mutate(values));

  return (
    <Modal
      size="lg"
      toggle={() => handleCreateModal()}
      isOpen={modal}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? 'Update Ticket' : 'Create Ticket'}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form onSubmit={submit}>
          <Row>
            <Col md={12} className="mb-3">
              <Label className="form-label">Comment</Label>
              <textarea className="form-control" rows={5} name="short_description" defaultValue={data?.comment} onInput={(e) => setValue('comment', e.target.value)}></textarea>
              {errors.comment && <span className="form-text text-danger">{errors.comment[0]}</span>}
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <Label className="form-label">Image</Label>
              <input className="form-control" name="image" type="file" multiple {...register("image")} />
              {errors.image && <span className="form-text text-danger">{errors.image[0]}</span>}
            </Col>
          </Row>

          <div className="d-md-flex justify-content-md-end">
            <button type="submit" className="btn btn-primary w-md" disabled={isLoading}>
              {isLoading ? (
                <>
                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                  Loading...
                </>
              ) : (
                <span>Submit</span>
              )}
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

TicketReply.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.boolean,
  toggle: PropTypes.func,
  refresh: PropTypes.func,
  handleCreateModal: PropTypes.func,
};

export default TicketReply