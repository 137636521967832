import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Category from "pages/Category"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import SubCategory from "pages/SubCategory"
import RequestQuote from "pages/RequestQuote"
import CreateInvoice from "pages/Quote/CreateInvoice"
import Product from "pages/Product"
import Invoice from "pages/Invoice"
import CreateUpdateInvoice from "pages/Invoice/CreateUpdateInvoice"
import Quote from "pages/Quote"
import CreateUpdateQuote from "pages/Quote/CreateUpdateQuote"
import Job from "../pages/Job/index"
import JobIncoming from "../pages/JobIncoming/index"
import JobReject from "../pages/JobReject/index"
import ShowJob from "pages/Job/show"
import CreateQuote from "pages/Job/CreateQuote"
import Client from "pages/Client"
import ShowQuote from "pages/Quote/show"
import ShowInvoice from "pages/Invoice/show"
import Blog from "pages/Blog"
import Contractor from "pages/Contractor"
import Admin from "pages/Admin"
import Ticket from "pages/Ticket"
import ShowTicket from "pages/Ticket/show"
import Banner from "pages/Banner"
import PageSetting from "pages/PageSetting"
import Faq from "pages/Faq"
import WorkOrder from "pages/WorkOrder"
import AssetManagement from "pages/AssetManagement"
import Children from "pages/AssetManagement/Children"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  // //profile
  { path: "/profile", component: UserProfile },
  { path: "/job", component: Job },
  { path: "/job-incoming", component: JobIncoming },
  { path: "/job-reject", component: JobReject },
  { path: "/job/:id", component: ShowJob },
  { path: "/job/:id/quote/create", component: CreateQuote },
  { path: "/job/:id/invoice/create", component: CreateInvoice },
  { path: "/invoice", component: Invoice },
  { path: "/invoice/create", component: CreateUpdateInvoice },
  { path: "/invoice/:id", component: ShowInvoice },
  { path: "/invoice/:id/update", component: CreateUpdateInvoice },
  { path: "/quote", component: Quote },
  { path: "/quote/create", component: CreateUpdateQuote },
  { path: "/quote/:id", component: ShowQuote },
  { path: "/quote/:id/update", component: CreateUpdateQuote },
  { path: "/work-orders", component: WorkOrder },
  { path: "/asset-management", component: AssetManagement },
  { path: "/asset-management/:id", component: Children },
  { path: "/product", component: Product },
  { path: "/admin", component: Admin },
  { path: "/client", component: Client },
  { path: "/contractor", component: Contractor },
  { path: "/category", component: Category },
  { path: "/category/subcategories/:category_id", component: SubCategory },
  { path: "/blog", component: Blog },
  { path: "/ticket", component: Ticket },
  { path: "/ticket/:id", component: ShowTicket },
  { path: "/banner", component: Banner },
  { path: "/page-setting", component: PageSetting },
  { path: "/faq", component: Faq },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/request-quote", component: RequestQuote },
]

export { publicRoutes, authProtectedRoutes }
