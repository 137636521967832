import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { Col, Form, Input, Label, Modal, Row, Table } from 'reactstrap';
import { get, post, postFormData, put } from 'helpers/api_helper';
import { showToast } from 'helpers/util';

const CreateUpdateFaq = ({ data, toggle, modal, handleCreateModal, refresh }) => {
  const [loading, setLoading] = useState(false)
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('')
  const [errors, setErrors] = useState([])

  useEffect(() => {
    console.log(data)
    if (!data) {
      setQuestion('')
      setAnswer('')
    } else {
      setQuestion(data.question)
      setAnswer(data.answer)
    }
  }, [data])

  const handleSubmit = async () => {
    setLoading(true);
    if (data) {
      var res = await put(`faqs/${data.id}`, {
        question,
        answer,
      });
    } else {
      var res = await post('faqs', {
        question,
        answer,
      });
    }
    showToast(res.message, !res.status && 'error')
    if (!res.status) {
      setErrors(res.errors)
      setLoading(false)
      return;
    }
    setLoading(false)
    toggle()
    refresh()
  }


  return (
    <Modal
      // size="lg"
      toggle={() => handleCreateModal()}
      isOpen={modal}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? 'Update Faq' : 'Create Faq'}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form onSubmit={(e) => {
          e.preventDefault()
          handleSubmit()
        }}>
          <Row>
            <Col md={12} className="mb-3">
              <Label htmlFor="question">Question</Label>
              <Input
                type="text"
                className="form-control"
                placeholder="Question"
                id="question"
                onInput={(e) => setQuestion(e.target.value)}
                value={question}
              />
              {errors.question && <span className="form-text text-danger">{errors.question[0]}</span>}
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <Label htmlFor="answer">Answer</Label>
              <Input
                type="text"
                className="form-control"
                placeholder="Answer"
                id="answer"
                onInput={(e) => setAnswer(e.target.value)}
                value={answer}
              />
              {errors.answer && <span className="form-text text-danger">{errors.answer[0]}</span>}
            </Col>
          </Row>

          <div className="d-md-flex justify-content-md-end">
            <button type="submit" className="btn btn-primary w-md" disabled={loading}>
              {loading ? (
                <>
                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                  Loading
                </>
              ) : (
                <span>Submit</span>
              )}
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

CreateUpdateFaq.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.boolean,
  toggle: PropTypes.func,
  refresh: PropTypes.func,
  handleCreateModal: PropTypes.func,
};

export default CreateUpdateFaq