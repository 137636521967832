import React, { useEffect, useState } from "react"
import { Image, Skeleton } from 'antd';
import { Link, useParams, withRouter } from "react-router-dom"
import classNames from "classnames"
import { Card, CardBody, CardTitle, Col, Container, Row, Table } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { get } from "helpers/api_helper"
import { formatCurrency, formatDate, priorityClasses, statusClasses } from "helpers/util"

const ShowQuote = props => {
  let { id } = useParams();
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  useEffect(() => {
    setLoading(true)
    getData()
    setLoading(false)
  }, [])


  const getData = async () => {
    const data = await get(`quotes/${id}`);
    setData(data.quote)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Tasks" breadcrumbItem="Job Request Detail" />
          {/* Render Breadcrumbs */}
          {data && !loading ? (
            <Row>
              <Col lg={8}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Customer Detail</CardTitle>
                      <div className="table-responsive">
                        <Table>
                          <thead>
                            <tr>
                              <td>Items</td>
                              <td>Name</td>
                              <td>Qty</td>
                              <td>Price</td>
                              <td>Total</td>
                            </tr>
                          </thead>
                          <tbody>
                            {data && data.products?.map((item, index) => (
                              <tr style={{ verticalAlign: 'middle' }} key={index}>
                                <td width="25%">
                                    {index + 1}
                                </td>
                                <td width="40%">{item.product.name}</td>
                                <td>{item.qty}</td>
                                <td>{formatCurrency(item.product.price)}</td>
                                <td>{formatCurrency(item.product.price * item.qty)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <Table responsive borderless className="float-end text-end" style={{ width: '40%', textAlignLast: 'center' }}>
                          <tr>
                            <th>Subtotal</th>
                            <td>{formatCurrency(data.products?.reduce((prev, next) => prev + (next.price * next.qty), 0))}</td>
                          </tr>
                          <tr>
                            <th>Discount</th>
                            <td>{formatCurrency(data.discount)}</td>
                          </tr>
                          <tr>
                            <th>Total</th>
                            <td>{formatCurrency(data.products?.reduce((prev, next) => prev + (next.price * next.qty), 0) - data.discount)}</td>
                          </tr>
                        </Table>
                      </div>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Detail</CardTitle>
                      <div className="table-responsive">
                        {/* <Table className="table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <th scope="row">Status :</th>
                              <td>
                                <span
                                  className={classNames(
                                    "badge badge-pill font-size-11",
                                    statusClasses[data.status?.toLowerCase()]
                                  )}
                                >
                                  {data.status}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Subject :</th>
                              <td>{data.subject}</td>
                            </tr>
                          </tbody>
                        </Table> */}
                      </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg={4}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Customer Detail</CardTitle>
                    <div className="table-responsive">
                      <div className="table-responsive">
                        <Table className="table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <th scope="row">Job Type :</th>
                              <td>{data.user?.name}</td>
                            </tr>
                            <tr>
                              <th scope="row">Email :</th>
                              <td>{data.user?.email}</td>
                            </tr>
                            <tr>
                              <th scope="row">Phone :</th>
                              <td>{data.user?.phone}</td>
                            </tr>
                            <tr>
                              <th scope="row">Address :</th>
                              <td>{data.user?.address}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Service Detail</CardTitle>
                    <div className="table-responsive">
                      <div className="table-responsive">
                        <Table className="table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <th scope="row">Date :</th>
                              <td>{data.quote_number}</td>
                            </tr>
                            {data.ref ? (
                              <tr>
                                <th scope="row">Due Date :</th>
                                <td>{data.quote_number}</td>
                              </tr>
                            ) : (<></>)}
                            {data.work_order_id ? (
                              <tr>
                                <th scope="row">Job ID :</th>
                                <td>{data.work_order_id}</td>
                              </tr>
                            ) : (<></>)}
                            <tr>
                              <th scope="row">Date :</th>
                              <td>{formatDate(data.date)}</td>
                            </tr>
                            <tr>
                              <th scope="row">Due Date :</th>
                              <td>{formatDate(data.due_date)}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Message</CardTitle>
                  {/* {data.message} */}
                </CardBody>
              </Card>

              {/* <Card>
                <CardBody>
                  <CardTitle className="mb-4">Images</CardTitle>
                    <Image.PreviewGroup>
                      {data?.image?.map(item => (
                        <Image src={process.env.REACT_APP_BASE_URL + item.url} alt="image" key={item.id} width="20%" />
                      ))}
                    </Image.PreviewGroup>
                </CardBody>
              </Card> */}
            </Row>
          ) : (
            <Row>
              <Col lg={8}>
                <Card>
                  <CardBody>
                    <Skeleton active paragraph={{ rows: 9 }} />
                  </CardBody>
                </Card>
              </Col>

              <Col lg={4}>
                <Card>
                  <CardBody>
                    <Skeleton active />
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <Skeleton active />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ShowQuote)
