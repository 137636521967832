import React, { useState, useMemo, useEffect, useCallback } from 'react'
import PropTypes, { element } from "prop-types";
import Select from "react-select";
import { Col, Form, Input, Label, Modal, Row, Table } from 'reactstrap';
import { get, post, postFormData, put } from 'helpers/api_helper';
import { showToast } from 'helpers/util';
import Upload from 'components/Common/Upload';
import { useForm } from "react-hook-form";

const CreateProduct = ({ data, toggle, modal, handleCreateModal, refresh }) => {
  const [loading, setLoading] = useState(false)
  const [categoryData, setCategoryData] = useState([])
  const [subcategoryData, setSubcategoryData] = useState([])
  const [errors, setErrors] = useState([])
  const { register, handleSubmit, setValue, formState: { formError } } = useForm();

  const getCategory = useCallback(async(val) => {
    setLoading(true)
    const res = await  get('/select-categories')
    let categories = [];
    res.map(item => categories.push({ label: item.name, value: item.id }));
    setCategoryData(categories)
    setLoading(false)
  }, [])

  const handleCategoryChange = async (category_id = null) => {
    setLoading(true)
    const res = await get('/select-subcategories', {
      params: {
        category_id
      }
    });

    let subcategories = [];
    res.map(item => subcategories.push({ label: item.name, value: item.id }));
    setSubcategoryData(subcategories)
    setLoading(false)
  }

  useEffect(() => {
    if (data) {
      const fields = ['category_id', 'subcategory_id', 'name', 'type', 'description', 'price', 'purchase_price'];
      fields.forEach(field => setValue(field, data[field]));
      setValue('subcategory_id', subcategoryData?.filter((val) => data.subcategories?.some
        ((value) => value.id == val.value)).map(element => element.value
        ))
    }
    if (!categoryData.length) {
      getCategory();
    }
      handleCategoryChange();
  }, [data])

  const submit = async (value) => {
    try {
      setLoading(true);
      let url = 'products';
      var formData = new FormData();
      formData.set('category_id', value.category_id ? value.category_id : '');
      formData.set('subcategory_id', JSON.stringify(value.subcategory_id) ? JSON.stringify(value.subcategory_id) : '');
      formData.set('name', value.name ? value.name : '');
      formData.set('type', value.type ? value.type : '');
      formData.set('description', value.description ? value.description : '');
      formData.set('price', value.price ? value.price : '');
      formData.set('purchase_price', value.purchase_price ? value.purchase_price : '');
      for (let i = 0; i < value.file?.length; i++) {
        formData.append('file[]', value.file[i])
      }

      if (data) {
        url = `products/${data.id}`;
        formData.set('_method', 'put');
      }

      var res = await postFormData(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      showToast(res.message, !res.status && 'error')
      if (!res.status) {
        setLoading(false)
        return setErrors(res.errors)
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
    toggle()
    refresh()
  }


  return (
    <Modal
      toggle={() => handleCreateModal()}
      isOpen={modal}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? 'Update Product' : 'Create Product'}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form onSubmit={handleSubmit(submit)}>
          <Row>
            <Col md={8} className="mb-3">
              <Label>Name</Label>
              <Input
                type="text"
                className="form-control"
                placeholder="Enter name"
                defaultValue={data ? data.name : ''}
                onInput={(e) => setValue('name', e.target.value)}
              />
              {errors.name && <span className="form-text text-danger">{errors.name[0]}</span>}
            </Col>
            <Col md={4} className="mb-3">
              <Label htmlFor="formrow-password-Input">Type</Label>
              <Select
                options={[
                  { label: 'Product', value: 'Product' },
                  { label: 'Service', value: 'Service' },
                ]}
                defaultValue={data ? { label: data.type, value: data.type } : ''}
                isLoading={loading}
                classNamePrefix="select2-selection"
                onChange={(e) => setValue('type', e.value)}
              />
              {errors.type && <span className="form-text text-danger">{errors.type[0]}</span>}
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Label htmlFor="formrow-password-Input">Category</Label>
              <Select
                options={categoryData}
                defaultValue={data ? categoryData.filter((val) => val.value == data.category_id) : ''}
                isLoading={loading}
                classNamePrefix="select2-selection"
                onChange={(e) => {
                  setValue('category_id', e.value)
                  handleCategoryChange(e.value)
                }}
              />
              {errors.category_id && <span className="form-text text-danger">{errors.category_id[0]}</span>}
            </Col>
            <Col md={6} className="mb-3">
              <Label htmlFor="formrow-password-Input">Subcategory</Label>
              <Select
                options={subcategoryData}
                isLoading={loading}
                defaultValue={data && subcategoryData ? subcategoryData?.filter((val) => data.subcategories?.some
                  ((value) => value.id == val.value)).map(element => (
                  { label: element.label, value: element.value }
                )) : ''}
                classNamePrefix="select2-selection"
                onChange={(e) => {
                  let value = Array.from(e, option => option.value);
                  setValue('subcategory_id', value)
                }}
                isMulti
              />
              {errors.subcategory_id && <span className="form-text text-danger">{errors.subcategory_id[0]}</span>}
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Label>Price</Label>
              <Input
                type="number"
                className="form-control"
                placeholder="Enter price"
                defaultValue={data ? data.price : ''}
                {...register('firstName')}
                onInput={(e) => setValue('price', e.target.value)}
              />
              {errors.price && <span className="form-text text-danger">{errors.price[0]}</span>}
            </Col>
            <Col md={6} className="mb-3">
              <Label>Purchase Price</Label>
              <Input
                type="number"
                className="form-control"
                placeholder="Enter purchase price"
                defaultValue={data ? data.purchase_price : ''}
                {...register('firstName')}
                onInput={(e) => setValue('purchase_price', e.target.value)}
              />
              {errors.purchase_price && <span className="form-text text-danger">{errors.purchase_price[0]}</span>}
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <Label>Description</Label>
              <textarea
                className="form-control"
                placeholder="Enter description"
                rows={5}
                defaultValue={data ? data.description : ''}
                {...register('firstName')}
                onInput={(e) => setValue('description', e.target.value)}
              />
              {errors.description && <span className="form-text text-danger">{errors.description[0]}</span>}
            </Col>
          </Row>

          <Row>
            <Col>
              <Upload setFile={(val) => setValue('file', val)} />
            </Col>
          </Row>

          <div className="d-md-flex justify-content-md-end">
            <button type="submit" className="btn btn-primary w-md" disabled={loading}>
              {loading ? (
                <>
                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                  Loading
                </>
              ) : (
                <span>Submit</span>
              )}
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

CreateProduct.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.boolean,
  toggle: PropTypes.func,
  refresh: PropTypes.func,
  handleCreateModal: PropTypes.func,
};

export default CreateProduct