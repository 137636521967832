// import Confirmation from 'components/Alert/Confirmation';
// import DeleteConfirmation from 'components/Alert/DeleteConfirmation';
import { del, get, post, put } from 'helpers/api_helper';
import { formatCurrency, formatDate, formatDateTime, priorityClasses, showToast, statusClasses } from 'helpers/util';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { Pagination } from 'react-laravel-paginex';
import { Link } from 'react-router-dom';
import { Badge, Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, Spinner, UncontrolledButtonDropdown } from 'reactstrap';
import classNames from 'classnames';
import Select from 'react-select';
import DeleteConfirmation from 'components/Alert/DeleteConfirmation';

const TableQuote = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [search, setSearch] = useState('')
  const [modal, setModal] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false)
  const [showDecline, setShowDecline] = useState(false)
  const [showDelete, setShowDelete] = useState(false)

  const toggle = () => {
    if (modal) {
      setModal(false);
    } else{
      setModal(true);
    }
  };

  const changeStatus = async (status, id) => {
    const res = await put(`/quotes/${id}/status`, {
      status
    });
    showToast(res.message, res.status ? 'success' : 'error');
    getData();
  }

  const deleteAction = async () => {
    try {
      setLoading(true)
      const res = await del(`/quotes/${id}`);
      setShowDelete(false)
      getData();
    } catch (error) {
      console.log(error)
      showToast('Failed To Delete Invoice', 'error')
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  const getData = useCallback(async (page = 1, search) => {
    setLoading(true)
    const res = await get('/quotes', {
      params: {
        page: page,
        search: search,
      }
    });
    setData(res);
    setLoading(false)
  }, []);

  const selectRow = {
    mode: "checkbox",
    hideSelectColumn: true,
    hideSelectAll: true,
  };

  //pagination customization
  const pageOptions = {
    page: 1,
    sizePerPage: 10,
    pageStartIndex: 1,
    totalSize: data?.total, // replace later with size(patients),
    custom: true,
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSearch = useCallback(debounce((page, search) => {
    getData(page, search)
  }, 800), [])

  const defaultSorted = [
    {
      dataField: "id",
      patient: "desc",
    },
  ];

  const columns = toggleModal => [
    {
      dataField: "quote_number",
      text: "Quote Number",
      sort: true,
      formatter: (cellContent, row) => (
        <Link to={`quote/${row.id}`}>{row.quote_number}</Link>
      ),
    },
    {
      dataField: "user.name",
      text: "Client Name",
      sort: true,
    },
    {
      dataField: "created_at",
      text: "Created At",
      sort: true,
      formatter: (cellContent, row) => (
        <span>{formatDate(cellContent)}</span>
      ),
    },
    {
      dataField: "due_date",
      text: "Due Date",
      sort: true,
      formatter: (cellContent, row) => (
        <Badge color="danger">{formatDate(cellContent)}</Badge>
      ),
    },
    {
      dataField: "total",
      text: "Amount",
      sort: true,
      style: { width: '10%' },
      formatter: (cellContent, row) => (
        <span>{formatCurrency(cellContent)}</span>
      ),
    },
    {
      dataField: "status",
      text: "Quote Status",
      sort: true,
      style: { width: '15%' },
      formatter: (cellContent, row) => (
        <Select
          options={[
            { label: 'New', value: 'New' },
            { label: 'Draft', value: 'Draft' },
            { label: 'Cancelled', value: 'Completed' },
            { label: 'Approved', value: 'Approved' },
          ]}
          style={{ width: '100%' }}
          isLoading={loading}
          classNamePrefix="select2-selection"
          defaultValue={{ label: row.status, value: row.status }}
          onChange={(e) => changeStatus(e.value, row.id)}
        />
      ),
    },
    {
      dataField: "action",
      text: "Action Status",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <Badge color="success">{cellContent}</Badge>
        </>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // hidden: getRole() == 'Staff' ? true : false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <UncontrolledButtonDropdown direction="start">
            <DropdownToggle caret>
              Action
            </DropdownToggle>
              <DropdownMenu>
              <Link to={`/quote/${row.id}`}>
                <DropdownItem>
                  Show
                </DropdownItem>
              </Link>
              <Link to={`/quote/${row.id}/update`}>
                <DropdownItem>
                  Edit
                </DropdownItem>
              </Link>
              <DropdownItem onClick={() => {
                setShowDelete(true);
              }}>
                Delete
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem>
                  Duplicate
              </DropdownItem>
              <DropdownItem>
                  Print <i className="fas fa-printer"></i>
              </DropdownItem>
              <DropdownItem>
                  Download PDF <i className="fas fa-file-pdf"></i>
              </DropdownItem>
              <DropdownItem>
                  Share <i className="bx bx-share"></i>
              </DropdownItem>
              {/* {(getRole() == 'Admin' || getRole() == 'Staff') && (
              )}
              {(getRole() == 'Admin' || getRole() == 'Staff') && (
                <DropdownItem onClick={() => {
                  setId(row.user.id);
                  setShowDecline(true)
                }}>
                  Decline
                </DropdownItem>
              )}
              {getRole() == 'Admin' && (
                <DropdownItem onClick={() => {
                  setId(row.id);
                  setShowDelete(true);
                }}>
                  Delete
                </DropdownItem>
              )} */}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </>
      ),
    },
  ];
  return (
    <React.Fragment>
      {loading ? (
        <>
          <Row className="mb-2">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative">
                  <input
                    placeholder="Search..."
                    className="form-control"
                    onInput={(e) => {
                      setSearch(e.target.value);
                      handleSearch(1, e.target.value)
                    }}
                    value={search}
                    type="text"
                  />
                  <i className="bx bx-search-alt search-icon" />
                </div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link to={`/quote/create`}>
                  <Button
                    type="button"
                    color="primary"
                    className="btn-rounded  mb-2 me-2"
                    onClick={toggle}
                  >
                    <i className="mdi mdi-plus me-1" />
                    Create Quote
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
          <Spinner className="ms-2 spinner-loading" color="primary" />
        </>
      ) : (
        <Row>
          <Col sm="12">
            {data && (
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={columns()}
                data={data.data}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    data={data.data}
                    columns={columns()}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col sm="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <input
                                  placeholder="Search..."
                                  className="form-control"
                                  onInput={(e) => {
                                    setSearch(e.target.value);
                                    handleSearch(1, e.target.value)
                                  }}
                                  value={search}
                                  type="text"
                                />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <Col sm="8">
                            <div className="text-sm-end">
                              <Link to={`/quote/create`}>
                                <Button
                                  type="button"
                                  color="primary"
                                  className="btn-rounded  mb-2 me-2"
                                  onClick={toggle}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    Create Quote
                                </Button>
                              </Link>
                              </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField="id"
                                responsive
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                noDataIndication={<div className='d-flex justify-content-center'>No data to display</div>}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-md-center mt-30">
                          <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                            <Pagination changePage={(e) => getData(e.page)} data={data} />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            )}
          </Col>
        </Row>
      )}
      {/* <CreateQuote modal={modal} toggle={toggle} handleCreateModal={toggle} data={null}
        refresh={getData} /> */}
      {/* <Confirmation showConfirm={showConfirm} setShowConfirm={() => setShowConfirm(false)} action={accept} accept={true} />
      <Confirmation showConfirm={showDecline} setShowConfirm={() => setShowDecline(false)} action={accept} accept={false} />
      <DeleteConfirmation showDelete={showDelete} setShowDelete={() => setShowDelete(false)} deleteAction={deleteAction} /> */}
      <DeleteConfirmation showDelete={showDelete} setShowDelete={() => setShowDelete(false)} deleteAction={deleteAction} />
    </React.Fragment>
  )
}

export default TableQuote