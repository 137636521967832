import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { postFormData } from "helpers/api_helper";

const Editor = ({ handleChange, name, ...props }) => {
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then(async (file) => {
            body.append("file", file);
            // let headers = new Headers();
            // headers.append("Origin", "http://localhost:3000");
            postFormData(`upload`, body, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            }).then((res) => {
              resolve({
                default: `${process.env.REACT_APP_BASE_URL}${res}`
              });
            })
              .catch((err) => {
                reject(err);
              });
            // fetch(`${API_URL}/${UPLOAD_ENDPOINT}`, {
            //   method: "post",
            //   body: body
            //   // mode: "no-cors"
            // })
            //   .then((res) => res.json())
            //   .then((res) => {
            //     resolve({
            //       default: `${API_URL}/${res.filename}`
            //     });
            //   })
            //   .catch((err) => {
            //     reject(err);
            //   });
          });
        });
      }
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  return (
    <div className="App">
      <CKEditor
        config={{
          extraPlugins: [uploadPlugin]
        }}
        editor={ClassicEditor}
        data={props.defaultValue ?? ''}
        onReady={(editor) => { }}
        onBlur={(event, editor) => { }}
        onFocus={(event, editor) => { }}
        onChange={(event, editor) => {
          handleChange(editor.getData());
        }}
        name={name}
        {...props}
      />
    </div>
  );
}

export default React.memo(Editor);
