import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Button, Card, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, Row, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar";
import { map } from "lodash";
import moment from "moment";
import { Image } from "antd";
import { useForm } from "react-hook-form";
import { replyTicket } from "helpers/Api/api";
import { showToast } from "helpers/util";
import { useMutation } from "@tanstack/react-query";

const Comment = ({data, refresh}) => {
  const { comments } = data;
  const [previewImage, setPreviewImage] = useState(null)
  const [comment, setComment] = useState('')
  const [messageBox, setMessageBox] = useState(null);
  const { handleSubmit, setValue, formState: { formErrors } } = useForm();
  const { mutate, isLoading } = useMutation(['comment'], (val) => replyTicket(val, data.id), {
    onSuccess: async (data) => {
      if (data.status) {
        showToast(data.message, 'success')
      } else {
        showToast(data.message, 'error')
      }
      await refresh()
      setTimeout(() => {
        scrollToBottom()
      }, 100);
    },
    onError: error => {
      console.log('error', error)
      showToast(error, 'error')
    },
  });

  const hiddenFileInput = React.useRef(null);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    setValue('image', fileUploaded);
    setPreviewImage(URL.createObjectURL(fileUploaded));
  };

  const scrollToBottom = () => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  const submit = handleSubmit(values => mutate(values));

  return (
    <div className="w-100 user-chat">
      <Card>
          <div className="chat-conversation p-3">
            <ul className="list-unstyled">
              <PerfectScrollbar
                style={{ height: "470px", overflowY: 'scroll' }}
                containerRef={ref => setMessageBox(ref)}
              >
                <li>
                  <div className="chat-day-title">
                    <span className="title">{data.subject}</span>
                  </div>
                </li>
                <li>
                  <div className="conversation-list">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        href="#"
                        className="btn nav-btn"
                        tag="i"
                      >
                        <i className="bx bx-dots-vertical-rounded" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem href="#">
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <div className="ctext-wrap">
                      <div className="conversation-name">
                        {data.user?.name}
                      </div>
                      {data?.image?.map(item => (
                        <Image src={process.env.REACT_APP_BASE_URL + item.image} alt="image" key={item.id} width={100} />
                      ))}
                      <p>{data.message}</p>
                      <p className="chat-time mb-0">
                        <i className="bx bx-time-five align-middle me-1" />
                        {moment(data.created_at).format(
                          "hh:mm"
                        )}
                      </p>
                    </div>
                  </div>
                </li>
                {comments &&
                  map(comments, message => (
                    <li
                      key={"test_k" + message.id}
                      className={
                        message.is_admin
                          ? "right"
                          : ""
                      }
                    >
                      <div className="conversation-list">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            href="#"
                            className="btn nav-btn"
                            tag="i"
                          >
                            <i className="bx bx-dots-vertical-rounded" />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem href="#">
                              Delete
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                        <div className="ctext-wrap">
                          <div className="conversation-name">
                            {message.user.name}
                          </div>
                          <p>{message.comment}</p>
                          <p className="chat-time mb-0">
                            <i className="bx bx-time-five align-middle me-1" />
                            {moment(message.created_at).format(
                              "hh:mm"
                            )}
                          </p>
                        </div>
                      </div>
                    </li>
                  ))
                }
              </PerfectScrollbar>
            </ul>
          </div>
          <Form onSubmit={submit}>
            <div className="p-3 chat-input-section">
              {previewImage && (
                <img src={previewImage} alt="image" className="img-fluid mb-2" width={100} />
              )}
              <Row className="align-items-center">
                <Col>
                  <div className="position-relative">
                    <input
                      type="text"
                      onChange={e => setValue('comment', e.target.value)}
                      className="form-control chat-input"
                      placeholder="Enter Message..."
                    />
                    <div className="chat-input-links">
                      <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                          <Link to="#" onClick={handleClick}>
                            <i
                              className="mdi mdi-file-image-outline"
                              id="Imagetooltip"
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target="Imagetooltip"
                            >
                              Images
                            </UncontrolledTooltip>
                          </Link>
                          <input
                            type="file"
                            ref={hiddenFileInput}
                            onChange={handleChange}
                            className="d-none"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col className="col-auto">
                  <Button
                    type="submit"
                    color="primary"
                    // onClick={() =>
                    //   addMessage(currentRoomId, currentUser.name)
                    // }
                    className="btn btn-primary btn-rounded chat-send w-md "
                  >
                    <span className="d-none d-sm-inline-block me-2">
                      Send
                    </span>{" "}
                    <i className="mdi mdi-send" />
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
      </Card>
    </div>
  )
}

export default React.memo(Comment);
