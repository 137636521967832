import React, { useEffect, useState } from 'react'
import { Text } from 'react'
import { Link, useParams } from 'react-router-dom';
import StepWizard from 'react-step-wizard';
import classnames from "classnames"
import { Card, CardBody, Col, Container, Form, Input, Label, NavItem, NavLink, Row, TabContent, Table, TabPane } from 'reactstrap';
import { get, postFormData } from 'helpers/api_helper';
import Upload from 'components/Common/Upload';
import axios from 'axios';
import { showToast } from 'helpers/util';
import { Skeleton } from 'antd';

const RequestQuote = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [file, setFile] = useState([])
  const [activeTab, setactiveTab] = useState(1)
  const [defaultUrl, setDefaultUrl] = useState('')
  const [passedSteps, setPassedSteps] = useState([1])
  const [categoriesData, setCategoriesData] = useState([])
  const [subcategoriesData, setSubcategoriesData] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('')
  const [selectedSubcategories, setSelectedSubcategories] = useState([])
  const [jobType, setJobType] = useState('')
  const [priority, setPriority] = useState('Flexible')
  const [date, setDate] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [postcode, setPostcode] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')

  const submit = async () => {
    try {
      setLoading(true);

      var formData = new FormData();
      formData.set('category_id', selectedCategory ? selectedCategory : '');
      formData.set('subcategory_id', JSON.stringify(selectedSubcategories) ? JSON.stringify(selectedSubcategories) : '');
      formData.set('work_type', jobType ? jobType : '');
      formData.set('priority', priority ? priority : '');
      formData.set('date', date ? date : '');
      formData.set('name', name ? name : '');
      formData.set('email', email ? email : '');
      formData.set('phone', phone ? phone : '');
      formData.set('address', address ? address : '');
      formData.set('postcode', postcode ? postcode : '');
      formData.set('subject', subject ? subject : '');
      formData.set('message', message ? message : '');
      for (let i = 0; i < file.length; i++) {
        formData.append('file[]', file[i])
      }

      var res = await postFormData(`request-quote`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      showToast(res.message, !res.status && 'error')
      if (!res.status) {
        return setErrors(res.errors)
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 5) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  useEffect(() => {
    getData();
  }, [selectedCategory])

  useEffect(() => {
    let url = new URL(window.location.href);
    const urlParams = new URLSearchParams(url.search);
    if (urlParams.get('id')) {
      setSelectedCategory(urlParams.get('id'));
      setactiveTab(2);
      setPassedSteps([1, 2])
    }
    // setCategoryId(urlParams.get('id'))
    setDefaultUrl(window.location.href);
    // getData();
    // window.addEventListener("beforeunload", refreshToDefaultUrl);
    // window.history.pushState('', 'Test', 'http://localhost:3000/request-quote');
    // return () => {
      // window.removeEventListener("beforeunload", refreshToDefaultUrl);
      // window.history.pushState('', 'Test', defaultUrl);
    // };
  }, [])

  const refreshToDefaultUrl = (e) => {
    // window.history.pushState('', 'Test', defaultUrl);
    window.setTimeout(() => {
      window.location = defaultUrl
    }, 0);
    window.onbeforeunload = null;
  };

  const getData = async () => {
    setLoading(true)
    setDefaultUrl(window.location.href);
    const categories = await get(`select-categories`)
    setCategoriesData(categories)
    const data = await get(`select-subcategories?category_id=${selectedCategory}`)
    setSubcategoriesData(data)
    setLoading(false)
  }

  const handleSubcategoryChange = (e) => {
    let subcategoryIds = selectedSubcategories;
    subcategoryIds.includes(e) ? subcategoryIds.splice(subcategoryIds.indexOf(e), 1) : subcategoryIds.push(e)
    setSelectedSubcategories(subcategoryIds)
  }

  const handleCategoryChange = (e) => {
    setactiveTab((prev) => prev + 1)
    setPassedSteps([...passedSteps, passedSteps.length])
    setSelectedCategory(e)
  }

  return (
    <>
    {/* <Container> */}
      <Row>
        <Col lg="12">
          <Card>
            <Row className="justify-content-center align-items-center p-2">
              <Col>
                <img src="https://xprogroup.com.au/storage/config-homes/August2021/gRMbN86ovNUsDp08j9Tk.png" alt="logo" width={150} />
              </Col>
              <Col className="text-end call-us">
                <span>CALL US NOW
                </span>
                  <a href="tel:0289595765" style={{ color: 'red' }}>
                    <b> 02 8959 5765</b>
                  </a>
              </Col>
            </Row>
            {/* <h4 className="card-title mb-4">Request Quote</h4> */}
            <CardBody>
              <div className="wizard clearfix">
                <div className="steps clearfix">
                  <ul>
                    <NavItem className={classnames({ current: activeTab === 1 })} style={{ display: 'flex', display: 'block' }}>
                      <NavLink
                        className={classnames({ current: activeTab === 1 })}
                        style={{ height: 70, display: 'flex', alignItems: 'center' }}
                        onClick={() => {
                          setactiveTab(1)
                        }}
                        disabled={!(passedSteps || []).includes(1)}
                      >
                        <span className="number" style={{ flex: 'none' }}>01</span> Select Service
                      </NavLink>
                    </NavItem>
                    <NavItem className={classnames({ current: activeTab === 2 })} style={{ display: 'flex', display: 'block' }}>
                      <NavLink
                        className={classnames({ current: activeTab === 2 })}
                        style={{ height: 70, display: 'flex', alignItems: 'center' }}
                        onClick={() => {
                          setactiveTab(2)
                        }}
                        disabled={!(passedSteps || []).includes(2)}
                      >
                        <span className="number" style={{ flex: 'none' }}>02</span> Service Type
                      </NavLink>
                    </NavItem>
                    <NavItem className={classnames({ current: activeTab === 3 })} style={{ display: 'flex', display: 'block' }}>
                      <NavLink
                        className={classnames({ active: activeTab === 3 })}
                        style={{ height: 70, display: 'flex', alignItems: 'center' }}
                        onClick={() => {
                          setactiveTab(3)
                        }}
                        disabled={!(passedSteps || []).includes(3)}
                      >
                        <span className="number ms-2">03</span> Job Type
                      </NavLink>
                    </NavItem>
                    <NavItem className={classnames({ current: activeTab === 4 })} style={{ display: 'flex', display: 'block' }}>
                      <NavLink
                        className={classnames({ active: activeTab === 4 })}
                        style={{ height: 70, display: 'flex', alignItems: 'center' }}
                        onClick={() => {
                          setactiveTab(4)
                        }}
                        disabled={!(passedSteps || []).includes(4)}
                      >
                        <span className="number" style={{ flex: 'none' }}>04</span> When would you like the job to start?
                      </NavLink>
                    </NavItem>
                    <NavItem className={classnames({ current: activeTab === 5 })} style={{ display: 'flex', display: 'block' }}>
                      <NavLink
                        className={classnames({ active: activeTab === 5 })}
                        style={{ height: 70, display: 'flex', alignItems: 'center' }}
                        onClick={() => {
                          setactiveTab(5)
                        }}
                        disabled={!(passedSteps || []).includes(5)}
                      >
                        <span className="number" style={{ flex: 'none' }}>05</span> Tell us a little more about the work
                      </NavLink>
                    </NavItem>
                  </ul>
                </div>
                {!loading ? (
                  <div className="content clearfix mt-4">
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId={1}>
                        <Form>
                          <Row>
                            {categoriesData.map((item, index) => (
                              <Col lg="3" key={index} className="col-6">
                                <div className="mb-3">
                                  <Card>
                                    <CardBody onClick={() => handleCategoryChange(item.id)} className="d-flex flex-column align-items-center" role="button">
                                        <img src={process.env.REACT_APP_BASE_URL + item.icon} alt="icon" className="img-fluid" width={50} />
                                          <p className="form-check-label" htmlFor={'cat' +item.id} style={{ textAlign: '-webkit-center' }}>
                                          {item.name}
                                          </p>
                                    </CardBody>
                                  </Card>
                                </div>
                              </Col>
                            ))}
                            {errors.category_id && <span className="form-text text-danger">{errors.category_id[0]}</span>}
                          </Row>
                        </Form>
                      </TabPane>
                      <TabPane tabId={2}>
                        <Form>
                          <Row>
                            {subcategoriesData.map((item, index) => (
                              <Col lg="3" key={index}>
                                <div className="mb-3">
                                  <Card>
                                    <CardBody onClick={() => handleSubcategoryChange(item.id)} style={{ textAlign: '-webkit-center' }}>
                                        <div className="form-check form-check-right mb-3">
                                          <input
                                            id={'sub' + item.id}
                                            type="checkbox"
                                            className="form-check-input"
                                            value={item.id}
                                            name="subcategory"
                                          />
                                          <label className="form-check-label" htmlFor={'sub' +item.id}>
                                            {item.name}
                                          </label>
                                        </div>
                                    </CardBody>
                                  </Card>
                                </div>
                              </Col>
                            ))}
                            {errors.subcategory_id && <span className="form-text text-danger">{errors.subcategory_id[0]}</span>}
                          </Row>
                        </Form>
                      </TabPane>
                      <TabPane tabId={3}>
                        <div>
                          <Form>
                            <Row>
                              <Col lg="6" className="mx-auto">
                                <Table striped>
                                  <tr>
                                    <div className="form-radio form-radio-right">
                                      <input
                                        type="radio"
                                        className="form-radio-input"
                                        name="jobtype"
                                        style={{ width: 20, height: 20 }}
                                        onChange={() => setJobType('Residential')}
                                      />
                                      <label className="form-radio-label">
                                        Residential
                                      </label>
                                    </div>
                                  </tr>
                                  <tr>
                                    <div className="form-radio form-radio-right mt-3">
                                      <input
                                        type="radio"
                                        className="form-radio-input"
                                        name="jobtype"
                                        style={{ width: 20, height: 20 }}
                                        onChange={() => setJobType('Commercial')}
                                      />
                                      <label className="form-radio-label">
                                        Commercial
                                      </label>
                                    </div>
                                  </tr>
                                  <tr>
                                    <div className="form-radio form-radio-right mt-3">
                                      <input
                                        type="radio"
                                        className="form-radio-input"
                                        name="jobtype"
                                        style={{ width: 20, height: 20 }}
                                        onChange={() => setJobType('Other')}
                                      />
                                      <label className="form-radio-label">
                                        Other
                                      </label>
                                    </div>
                                  </tr>
                                </Table>
                                {errors.work_type && <span className="form-text text-danger">{errors.work_type[0]}</span>}
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </TabPane>
                      <TabPane tabId={4}>
                        <div>
                          <Form>
                            <Row>
                              <Col lg="6" className="mx-auto">
                                <div className="mb-3">
                                  <Label>Priority</Label>
                                  <select className="form-select" onChange={(e) => setPriority(e.target.value)}>
                                    <option value="Flexible">Flexible</option>
                                    <option value="Can Wait">Can Wait</option>
                                    <option value="Need It Today">Need It Today</option>
                                    <option value="Need It Tomorrow">Need It Tomorrow</option>
                                  </select>
                                </div>
                                {errors.priority && <span className="form-text text-danger">{errors.priority[0]}</span>}
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6" className="mx-auto">
                                <div className="mb-3">
                                  <Label for="basicpill-expiration-input13">
                                    Pick a Date
                                  </Label>
                                  <Input
                                    type="datetime-local"
                                    className="form-control"
                                    id="basicpill-expiration-input13"
                                    placeholder="Card Expiration Date"
                                    onChange={(e) => setDate(e.target.value)}
                                  />
                                </div>
                                {errors.date && <span className="form-text text-danger">{errors.date[0]}</span>}
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </TabPane>
                      <TabPane tabId={5}>
                        <Row className="justify-content-center">
                          <Col md={3}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-name-Input">Name</Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-name-Input"
                                placeholder="Enter Your Name"
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                            {errors.name && <span className="form-text text-danger">{errors.name[0]}</span>}
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-email-Input">Email</Label>
                              <Input
                                type="email"
                                className="form-control"
                                id="formrow-email-Input"
                                placeholder="Enter Your Email ID"
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                            {errors.email && <span className="form-text text-danger">{errors.email[0]}</span>}
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-phone-Input">Phone</Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-phone-Input"
                                placeholder="Enter Your Phone"
                                onChange={(e) => setPhone(e.target.value)}
                              />
                            </div>
                            {errors.phone && <span className="form-text text-danger">{errors.phone[0]}</span>}
                          </Col>
                        </Row>
                        <Row className="justify-content-center">
                          <Col md={7}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-address-Input">Address</Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-address-Input"
                                placeholder="Enter Your Address"
                                onChange={(e) => setAddress(e.target.value)}
                              />
                            </div>
                            {errors.address && <span className="form-text text-danger">{errors.address[0]}</span>}
                          </Col>
                          <Col md={2}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-postcode-Input">Postcode</Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-postcode-Input"
                                placeholder="Enter Your Postcode"
                                onChange={(e) => setPostcode(e.target.value)}
                              />
                            </div>
                            {errors.postcode && <span className="form-text text-danger">{errors.postcode[0]}</span>}
                          </Col>
                        </Row>
                        <Row className="justify-content-center">
                          <Col md={9}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-subject-Input">Subject</Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-subject-Input"
                                placeholder="Enter Your Subject"
                                onChange={(e) => setSubject(e.target.value)}
                              />
                            </div>
                            {errors.subject && <span className="form-text text-danger">{errors.subject[0]}</span>}
                          </Col>
                        </Row>
                        <Row className="justify-content-center">
                          <Col md={9}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-message-Input">Message</Label>
                              <textarea
                                type="text"
                                className="form-control"
                                id="formrow-message-Input"
                                rows={5}
                                placeholder="Enter Your Message"
                                onChange={(e) => setMessage(e.target.value)}
                              ></textarea>
                            </div>
                            {errors.message && <span className="form-text text-danger">{errors.message[0]}</span>}
                          </Col>
                        </Row>
                        <Row className="justify-content-center">
                          <Col md={9}>
                            <Upload setFile={setFile} />
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </div>
                ) : (
                    <Row>
                      <Col>
                        <Card>
                          <CardBody>
                            <Skeleton active paragraph={{ rows: 4 }} />
                          </CardBody>
                        </Card>

                        <Card>
                          <CardBody>
                            <Skeleton active paragraph={{ rows: 4 }} />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                )}
                <div className="actions clearfix">
                  <ul>
                    <li
                      className={
                        activeTab === 1 ? "previous disabled" : "previous"
                      }
                    >
                      <Link
                        to="#"
                        onClick={() => {
                          toggleTab(activeTab - 1)
                        }}
                      >
                        Previous
                      </Link>
                    </li>
                    <li>
                      {activeTab !== 5 ? (
                        <Link
                          to="#"
                          onClick={() => {
                            toggleTab(activeTab + 1)
                          }}
                        >
                          Next
                        </Link>
                      ) : (
                        <button className="btn btn-primary" onClick={() => submit()}>Submit</button>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    {/* </Container> */}
    </>
  )
}

export default RequestQuote;