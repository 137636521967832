import PropTypes from 'prop-types';
import React, { useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';
import { Col, Form, Label, Modal, Row } from 'reactstrap';

const NotesConfirmation = ({ data, modal, toggle, action }) => {
  const [loading, setLoading] = useState(false)
  const [reason, setReason] = useState('')

  return (
    <Modal
      size="lg"
      toggle={() => toggle()}
      isOpen={modal}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          Reject Reason
        </h5>
        <button
          onClick={() => toggle()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form onSubmit={(e) => {
          setLoading(true)
          e.preventDefault()
          action('Reject', data.id, reason)
          toggle()
          setLoading(false)
        }}>
          <Row>
            <Col md={12} className="mb-3">
              {/* <Label htmlFor="reason">Reason</Label> */}
              <textarea
                type="text"
                className="form-control"
                placeholder="Reason"
                id="reason"
                rows={5}
                onInput={(e) => setReason(e.target.value)}
                required
              />
            </Col>
          </Row>

          <div className="d-md-flex justify-content-md-end">
            <button type="submit" className="btn btn-primary w-md" disabled={loading}>
              {loading ? (
                <>
                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                  Loading
                </>
              ) : (
                <span>Submit</span>
              )}
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

NotesConfirmation.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  action: PropTypes.func,
};

export default NotesConfirmation