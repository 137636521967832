import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { Col, Form, Input, Label, Modal, Row, Table } from 'reactstrap';
import { get, post, put } from 'helpers/api_helper';
import { showToast } from 'helpers/util';
import { useParams } from 'react-router-dom';

const CreateUpdateSubCategory = ({ data, toggle, modal, handleCreateModal, refresh }) => {
  const { category_id } = useParams();
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [errors, setErrors] = useState([])

  useEffect(() => {
    if (!data) {
      setName('')
    } else {
      setName(data.name)
    }
  }, [data])

  const handleSubmit = async () => {
    setLoading(true);
    if (data) {
      var res = await put(`subcategories/${data.id}`, {
        category_id: data.category_id,
        name,
      });
    } else {
      var res = await post('subcategories', {
        category_id,
        name,
      });
    }
    showToast(res.message, !res.status && 'error')
    setLoading(false)
    if (!res.status) {
      setErrors(res.errors)
      return;
    }
    toggle()
    refresh()
  }


  return (
    <Modal
      // size="lg"
      toggle={() => handleCreateModal()}
      isOpen={modal}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? 'Update Sub Category' : 'Create Sub Category'}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form onSubmit={(e) => {
          e.preventDefault()
          handleSubmit()
        }}>
          <Row>
            <Col md={12} className="mb-3">
              <Label htmlFor="name">Name</Label>
              <Input
                type="text"
                className="form-control"
                placeholder="Name"
                id="name"
                onInput={(e) => setName(e.target.value)}
                value={name}
              />
              {errors.postcode && <span className="form-text text-danger">{errors.postcode[0]}</span>}
            </Col>
          </Row>

          <div className="d-md-flex justify-content-md-end">
            <button type="submit" className="btn btn-primary w-md" disabled={loading}>
              {loading ? (
                <>
                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                  Loading
                </>
              ) : (
                <span>Submit</span>
              )}
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

CreateUpdateSubCategory.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.boolean,
  toggle: PropTypes.func,
  refresh: PropTypes.func,
  handleCreateModal: PropTypes.func,
};

export default CreateUpdateSubCategory