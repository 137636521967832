import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Row,
  Col,
  Card,
} from "reactstrap"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom";
import toastr from 'toastr'

const Upload = ({ setFile, max = 10 }) => {
  const [selectedFiles, setselectedFiles] = useState([])


  function handleAcceptedFiles(files) {
    if (selectedFiles.length + 1 > max) return toastr.error(`Maximum ${max} files allowed`)
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(prev => [...prev, ...files])
    setFile([...selectedFiles, ...files]);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <>
      <Dropzone
        multiple={true}
        maxFiles={1}
        onDrop={acceptedFiles => {
          handleAcceptedFiles(acceptedFiles)
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone">
            <div
              className="dz-message needsclick mt-2"
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <div className="mb-3">
                <i className="display-4 text-muted bx bxs-cloud-upload" />
              </div>
              <h4>Drop files here or click to upload.</h4>
            </div>
          </div>
        )}
      </Dropzone>
      <div className="dropzone-previews mt-3" id="file-previews">
        {selectedFiles.map((f, i) => {
          return (
            <Card
              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
              key={i}
            >
              <div className="p-2">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <img
                      data-dz-thumbnail=""
                      height="80"
                      className="avatar-sm rounded bg-light"
                      alt={f.name}
                      src={f.preview}
                    />
                  </Col>
                  <Col>
                    <Link
                      to="#"
                      className="text-muted font-weight-bold"
                    >
                      {f.name}
                    </Link>
                    <p className="mb-0">
                      <strong>{f.formattedSize}</strong>
                    </p>
                  </Col>
                </Row>
              </div>
            </Card>
          )
        })}
      </div>
    </>
  )
}

export default Upload;