import moment from "moment";
import toastr from "toastr";

export function currency(value) {
  const formattedCurrency = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
  }).format(value);
  return formattedCurrency;
}
export function formatDate(date) {
  // const date1 = moment(date).format("DD MMM Y");
  const date1 = moment(date).format("DD/MM/Y");
  return date1 == 'Invalid date' ? '' : date1;
}
export function formatDateInput(date) {
  const date1 = moment(date).format("YYYY-MM-DD");
  return date1 == 'Invalid date' ? null : date1;
}
export function formatDateTime(date) {
  const date1 = moment(new Date(date)).format("DD/MM/YY h:mm A");
  return date1 == 'Invalid date' ? '' : date1;
}
export function formatDateTimeInput(date) {
  const date1 = moment(new Date(date)).format("YYYY-MM-DDTHH:mm");
  return date1;
}
export function formatCurrency(amount) {
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(amount);
}
export function showToast(message, type = null) {
  toastr.options = {
    positionClass: 'toast-top-right',
    timeOut: 3000,
    closeButton: true,
    progressBar: true,
    newestOnTop: true,
    showEasing: 'swing',
    showDuration: true,
  };

  // setTimeout(() => toastr.success(`Settings updated `), 300)
  //Toaster Types
  if (type === "info") toastr.info(message, 'Information');
  else if (type === "warning") toastr.warning(message, 'Warning!');
  else if (type === "error") toastr.error(message, 'Error!');
  else toastr.success(message, 'Success!');
}

export const statusClasses = {
  Waiting: "badge-soft-secondary",
  Pending: "badge-soft-warning",
  Processing: "badge-soft-primary",
  Completed: "badge-soft-success",
  Replied: "badge-soft-success",
}

export const priorityClasses = {
  'flexible': "badge-soft-primary",
  'can wait': "badge-soft-success",
  'need it today': "badge-soft-danger",
  'need it tomorrow': "badge-soft-danger",
  'Low': "badge-soft-success",
  'Medium': "badge-soft-warning",
  'High': "badge-soft-danger",
}