import React from 'react'
import { MetaTags } from 'react-meta-tags';
import Breadcrumbs from "components/Common/Breadcrumb";
import { Card, CardBody, Col, Container, Row} from 'reactstrap';
import Table from "./Component/table"

const Quote = () => {
  return(
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Quote" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Table />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Quote;
