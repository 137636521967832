import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from "prop-types";
import { Col, Form, Input, Label, Modal, Row, Table } from 'reactstrap';
import { get, post, postFormData, put } from 'helpers/api_helper';
import { showToast } from 'helpers/util';
import Editor from 'components/Common/Editor';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';

const CreateUpdateBlog = ({ data, toggle, modal, handleCreateModal, refresh }) => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const { register, handleSubmit, setValue, formState: { formErrors }, reset } = useForm();

  useEffect(() => {
    if (data) {
      const fields = ['id', 'title', 'short_description', 'content', 'status'];
      fields.forEach(field => setValue(field, data[field]));
    }
  }, [data])

  const submitForm = async (value) => {
    var formData = new FormData();
    formData.set('title', value.title);
    formData.set('image', value.image[0]);
    formData.set('short_description', value.short_description);
    formData.set('content', value.content);
    formData.set('status', Number(value.status));
    if (data) {
      formData.set('_method', 'put');
      var res = await postFormData(`blogs/${data.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
    } else {
      var res = await postFormData(`blogs`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
    }
    return res;
  }

  const { mutate, isLoading } = useMutation(['blogs'], submitForm, {
    onSuccess: data => {
      if (data.status) {
        showToast(data.message, 'success')
        toggle()
      }else{
        setErrors(data.errors);
      }
      refresh()
    },
    onError: error => {
      console.log('error', error)
      showToast(error, 'error')
    },
  });

  const submit = handleSubmit(values => mutate(values));

  return (
    <Modal
      size="lg"
      toggle={() => handleCreateModal()}
      isOpen={modal}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? 'Update Blog' : 'Create Blog'}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form onSubmit={submit}>
          <Row>
            <Col md={12} className="mb-3">
              <Label>Title</Label>
              <input
                type="text"
                className="form-control"
                placeholder="Title"
                name="title"
                onInput={(e) => setValue('title', e.target.value)}
                defaultValue={data ? data.title : ''}
              />
              {errors.title && <span className="form-text text-danger">{errors.title[0]}</span>}
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <Label className="form-label">Image</Label>
              <input className="form-control" name="image" type="file" {...register("image")} />
              {errors.image && <span className="form-text text-danger">{errors.image[0]}</span>}
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <Label className="form-label">Short Description</Label>
              <textarea className="form-control" rows={5} name="short_description" defaultValue={data?.short_description} onInput={(e) => setValue('short_description', e.target.value)}></textarea>
              {errors.short_description && <span className="form-text text-danger">{errors.short_description[0]}</span>}
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <Label htmlFor="content">Content</Label>
              <Editor handleChange={(val) => setValue('content', val)} defaultValue={data?.content} />
              {errors.content && <span className="form-text text-danger">{errors.content[0]}</span>}
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <div className="form-check form-switch mb-3">
                <input
                  id="blog-status"
                  type="checkbox"
                  className="form-check-input"
                  name="status"
                  defaultChecked={Boolean(data?.status)}
                  onClick={(e) => setValue('status', e.target.checked)}
                />
                <label className="form-check-label" htmlFor="blog-status">Status</label>
              </div>
              {errors.status && <span className="form-text text-danger">{errors.status[0]}</span>}
            </Col>
          </Row>

          <div className="d-md-flex justify-content-md-end">
            <button type="submit" className="btn btn-primary w-md" disabled={isLoading}>
              {isLoading ? (
                <>
                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                  Loading...
                </>
              ) : (
                <span>Submit</span>
              )}
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

CreateUpdateBlog.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.boolean,
  toggle: PropTypes.func,
  refresh: PropTypes.func,
  handleCreateModal: PropTypes.func,
};

export default CreateUpdateBlog