import classnames from 'classnames';
import React, { useCallback, useState } from 'react'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, CardText, CardTitle, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import SettingPage from './Component/SettingPage';
import { useQuery } from '@tanstack/react-query';
import { setting } from 'helpers/Api/api';

const PageSetting = () => {
  const [activeTab, setactiveTab] = useState("1");
  const [data, setData] = useState([])
  const toggle = useCallback((tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  }, []);

  const { isLoading, refetch } = useQuery(['settings'], () => setting(), {
    onSuccess: (res) => {
      setData(res.settings);
    },
    onError: err => console.log(err)
  });
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Setting" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Nav tabs>
                    {data.filter(item => item.group == 'PageSetting').map((item, index) => (
                      <NavItem key={item.id}>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab === item.id,
                          })}
                          onClick={() => {
                            toggle(item.id);
                          }}
                        >
                          {item.display_name}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>

                  <TabContent activeTab={activeTab} className="p-3 text-muted">
                    {data.filter(item => item.group == 'PageSetting').map((item, index) => (
                      <TabPane tabId={item.id} key={item.id}>
                        <Row>
                          <Col sm="12">
                            <SettingPage data={item} refetch={refetch} />
                          </Col>
                        </Row>
                      </TabPane>
                    ))}
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default React.memo(PageSetting)