// import Confirmation from 'components/Alert/Confirmation';
// import DeleteConfirmation from 'components/Alert/DeleteConfirmation';
import { del, get, post } from 'helpers/api_helper';
import { formatDateTime } from 'helpers/util';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { Pagination } from 'react-laravel-paginex';
import { Link } from 'react-router-dom';
import CreateUpdateBlog from '../CreateUpdateBlog';
import { Badge, Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, Spinner, UncontrolledButtonDropdown } from 'reactstrap';
import DeleteConfirmation from 'components/Alert/DeleteConfirmation';

const TableBlog = () => {
  const [loading, setLoading] = useState(true)
  const [id, setId] = useState('')
  const [data, setData] = useState(null)
  const [search, setSearch] = useState('')
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null)
  const [showConfirm, setShowConfirm] = useState(false)
  const [showDecline, setShowDecline] = useState(false)
  const [showDelete, setShowDelete] = useState(false)

  const toggle = (value = null) => {
    if (modal) {
      setModal(false);
    } else{
      setModal(true);
    }
    setModalData(value)
  };

  const deleteAction = async () => {
    const res = await del(`/blogs/${id}`);
    setShowDelete(false)
    getData();
  }

  const getData = useCallback(async (page = 1, search) => {
    setLoading(true)
    const res = await get('/blogs', {
      params: {
        page: page,
        search: search,
      }
    });
    setData(res);
    setLoading(false)
  }, []);

  const selectRow = {
    mode: "checkbox",
    hideSelectColumn: true,
    hideSelectAll: true,
  };

  //pagination customization
  const pageOptions = {
    page: 1,
    sizePerPage: 10,
    pageStartIndex: 1,
    totalSize: data?.total, // replace later with size(patients),
    custom: true,
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSearch = useCallback(debounce((page, search) => {
    getData(page, search)
  }, 800), [])

  const defaultSorted = [
    {
      dataField: "id",
      patient: "desc",
    },
  ];

  const columns = toggleModal => [
    {
      dataField: "title",
      text: "Title",
      sort: true,
    },
    {
      dataField: "image",
      text: "Image",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        row.image ? <img src={process.env.REACT_APP_BASE_URL + row.image} alt={row.name} width={50} /> : '-'
      ),
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        row.status ? 'Active' : 'Inactive'
      ),
    },
    {
      dataField: "created_at",
      text: "Date",
      sort: true,
      formatter: (cellContent, row) => formatDateTime(row.created_at),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // hidden: getRole() == 'Staff' ? true : false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <UncontrolledButtonDropdown>
            <DropdownToggle caret>
              Action
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => toggle(row)}>
                Edit
              </DropdownItem>
              <DropdownItem onClick={() => {
                setId(row.id);
                setShowDelete(true);
              }}>
                Delete
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </>
      ),
    },
  ];
  return (
    <React.Fragment>
      <Row className="mb-2">
        <Col sm="4">
          <div className="search-box me-2 mb-2 d-inline-block">
            <div className="position-relative">
              <input
                placeholder="Search..."
                className="form-control"
                onInput={(e) => {
                  setSearch(e.target.value);
                  handleSearch(1, e.target.value)
                }}
                value={search}
                type="text"
              />
              <i className="bx bx-search-alt search-icon" />
            </div>
          </div>
        </Col>
        <Col sm="8">
          <div className="text-sm-end">
            <Button
              type="button"
              color="primary"
              className="btn-rounded  mb-2 me-2"
              onClick={() => toggle()}
            >
              <i className="mdi mdi-plus me-1" />
              Create Blog
            </Button>
          </div>
        </Col>
      </Row>
      {!loading ? (
        <Row>
          <Col sm="12">
            {data && (
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={columns()}
                data={data.data}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    data={data.data}
                    columns={columns()}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField="id"
                                responsive
                                bpatiented={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                noDataIndication={<div className='d-flex justify-content-center'>No data to display</div>}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-md-center mt-30">
                          <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                            <Pagination changePage={(e) => getData(e.page)} data={data} />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            )}
          </Col>
        </Row>
        ) : (
        <Spinner className="ms-2 spinner-loading" color="primary" />
      )}
      <CreateUpdateBlog modal={modal} toggle={toggle} handleCreateModal={toggle} data={modalData}
        refresh={getData} />
      {/* <Confirmation showConfirm={showConfirm} setShowConfirm={() => setShowConfirm(false)} action={accept} accept={true} />
      <Confirmation showConfirm={showDecline} setShowConfirm={() => setShowDecline(false)} action={accept} accept={false} />
      <DeleteConfirmation showDelete={showDelete} setShowDelete={() => setShowDelete(false)} deleteAction={deleteAction} /> */}
      <DeleteConfirmation showDelete={showDelete} setShowDelete={() => setShowDelete(false)} deleteAction={deleteAction} />
    </React.Fragment>
  )
}

export default TableBlog