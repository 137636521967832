import React, { useState, useMemo, useEffect, useCallback } from 'react'
import PropTypes, { element } from "prop-types";
import Select from "react-select";
import { Col, Form, Input, Label, Modal, Row, Table } from 'reactstrap';
import { get, post, postFormData, put } from 'helpers/api_helper';
import { showToast } from 'helpers/util';
import Upload from 'components/Common/Upload';
import { useForm } from "react-hook-form";
import { createAdmin, updateAdmin } from 'helpers/Api/api';
import { useMutation } from '@tanstack/react-query';

const CreateUpdateAdmin = ({ data, toggle, modal, handleCreateModal, refresh }) => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const { register, handleSubmit, setValue, formState: { formError } } = useForm();
  const { mutate, isLoading } = useMutation(['createUpdateAdmin'], (value) => submitForm(value), {
    onSuccess: data => {
      if (data.status) {
        showToast(data.message, 'success');
        toggle()
        refresh()
      }else{
        setErrors(data.errors);
        showToast(data.message, 'error');
      }
    },
    onError: error => {
      console.log('error', error)
      showToast(error, 'error');
    },
  });

  const submit = handleSubmit(values => mutate(values));

  const submitForm = (value) => {
    if (value) {
      var res = updateAdmin(value);
    }else{
      var res = createAdmin(value);
    }
    return res
  }

  useEffect(() => {
    if (data) {
      const fields = ['id', 'name', 'email', 'phone', 'address'];
      fields.forEach(field => setValue(field, data[field]));
    }
  }, [data])

  return (
    <Modal
      toggle={() => handleCreateModal()}
      isOpen={modal}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? 'Update Admin' : 'Create Admin'}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form onSubmit={submit}>
          <Row>
            <Col md={12} className="mb-3">
              <Label>Name</Label>
              <Input
                type="text"
                className="form-control"
                placeholder="Enter name"
                defaultValue={data ? data.name : ''}
                onInput={(e) => setValue('name', e.target.value)}
              />
              {errors.name && <span className="form-text text-danger">{errors.name[0]}</span>}
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Label>Email</Label>
              <Input
                type="email"
                className="form-control"
                placeholder="Enter email"
                defaultValue={data ? data.email : ''}
                onInput={(e) => setValue('email', e.target.value)}
              />
              {errors.email && <span className="form-text text-danger">{errors.email[0]}</span>}
            </Col>
            <Col md={6} className="mb-3">
              <Label>Phone</Label>
              <Input
                type="number"
                className="form-control"
                placeholder="Enter phone number"
                defaultValue={data ? data.phone : ''}
                onInput={(e) => setValue('phone', e.target.value)}
              />
              {errors.phone && <span className="form-text text-danger">{errors.phone[0]}</span>}
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <Label>Address</Label>
              <textarea
                className="form-control"
                placeholder="Enter address"
                rows={5}
                defaultValue={data ? data.address : ''}
                onInput={(e) => setValue('address', e.target.value)}
              />
              {errors.address && <span className="form-text text-danger">{errors.address[0]}</span>}
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Label>Password</Label>
              <Input
                type="password"
                className="form-control"
                placeholder="Enter password"
                onInput={(e) => setValue('password', e.target.value)}
              />
            </Col>
            <Col md={6} className="mb-3">
              <Label>Password Confirmation</Label>
              <Input
                type="password"
                className="form-control"
                placeholder="Enter password confirmation"
                onInput={(e) => setValue('password_confirmation', e.target.value)}
              />
            </Col>
            {errors.password && <span className="form-text text-danger">{errors.password[0]}</span>}
          </Row>

          <div className="d-md-flex justify-content-md-end">
            <button type="submit" className="btn btn-primary w-md" disabled={loading}>
              {loading ? (
                <>
                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                  Loading
                </>
              ) : (
                <span>Submit</span>
              )}
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

CreateUpdateAdmin.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.boolean,
  toggle: PropTypes.func,
  refresh: PropTypes.func,
  handleCreateModal: PropTypes.func,
};

export default CreateUpdateAdmin