import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { Col, Form, Input, Label, Modal, Row, Table } from 'reactstrap';
import { get, post, postFormData, put } from 'helpers/api_helper';
import { showToast } from 'helpers/util';

const CreateUpdateAssetManagement = ({ data, toggle, modal, handleCreateModal, refresh }) => {
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [icon, setIcon] = useState('')
  const [comingSoon, setComingSoon] = useState(false)
  const [errors, setErrors] = useState([])

  useEffect(() => {
    console.log(data)
    if (!data) {
      setName('')
      setComingSoon(false)
      setIcon('')
    } else {
      setName(data.name)
      setComingSoon(data.coming_soon)
      setIcon('')
    }
  }, [data])

  const handleSubmit = async () => {
    setLoading(true);
    var formData = new FormData();
    formData.set('name', name ? name : '');
    formData.set('icon', icon ? icon : '');
    formData.set('coming_soon', comingSoon);
    if (data) {
      formData.set('_method', 'put');
      var res = await postFormData(`categories/${data.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
    } else {
      var res = await postFormData(`categories`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      showToast(res.message, !res.status && 'error')
      if (!res.status) {
        return setErrors(res.errors)
      }
    }
    showToast(res.message, !res.status && 'error')
    if (!res.status) {
      setErrors(res.errors)
      setLoading(false)
      return;
    }
    setLoading(false)
    toggle()
    refresh()
  }


  return (
    <Modal
      // size="lg"
      toggle={() => handleCreateModal()}
      isOpen={modal}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? 'Update Category' : 'Create Category'}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form onSubmit={(e) => {
          e.preventDefault()
          handleSubmit()
        }}>
          <Row>
            <Col md={12} className="mb-3">
              <Label htmlFor="name">Name</Label>
              <Input
                type="text"
                className="form-control"
                placeholder="Name"
                id="name"
                onInput={(e) => setName(e.target.value)}
                value={name}
              />
              {errors.name && <span className="form-text text-danger">{errors.name[0]}</span>}
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <Label htmlFor="formFile" className="form-label">Icon</Label>
              <Input className="form-control" type="file" id="formFile" onChange={(e) => setIcon(e.target.files[0])} />
              {errors.icon && <span className="form-text text-danger">{errors.icon[0]}</span>}
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <div
                className="form-check form-switch mb-3"
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitch1"
                  checked={comingSoon ? true : false}
                  onClick={(e) => setComingSoon(!e.target.checked)}
                />
                <label
                  className="form-check-label"
                  htmlFor="customSwitch1"
                >
                  Coming Soon
                </label>
              </div>
              {errors.coming_soon && <span className="form-text text-danger">{errors.coming_soon[0]}</span>}
            </Col>
          </Row>

          <div className="d-md-flex justify-content-md-end">
            <button type="submit" className="btn btn-primary w-md" disabled={loading}>
              {loading ? (
                <>
                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                  Loading
                </>
              ) : (
                <span>Submit</span>
              )}
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

CreateUpdateAssetManagement.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.boolean,
  toggle: PropTypes.func,
  refresh: PropTypes.func,
  handleCreateModal: PropTypes.func,
};

export default CreateUpdateAssetManagement