import React, { useEffect, useState } from "react"
import { Image, Skeleton } from 'antd';
import { Link, useParams, withRouter } from "react-router-dom"
import classNames from "classnames"
import { Card, CardBody, CardTitle, Col, Container, Row, Table } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { get } from "helpers/api_helper"
import { formatDateTime, priorityClasses, statusClasses } from "helpers/util"

const ShowJob = props => {
  let { id } = useParams();
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  useEffect(() => {
    setLoading(true)
    getData()
    setLoading(false)
  }, [])


  const getData = async () => {
    const data = await get(`work-orders/${id}`);
    setData(data.work_order)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Tasks" breadcrumbItem="Job Request Detail" />
          {/* Render Breadcrumbs */}
          {data || loading ? (
            <Row>
              <Col lg={8}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Customer Detail</CardTitle>
                      <div className="table-responsive">
                        <Table className="table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <th scope="row">Full Name :</th>
                              <td>{data.name}</td>
                            </tr>
                            <tr>
                              <th scope="row">Mobile :</th>
                              <td>{data.phone}</td>
                            </tr>
                            <tr>
                              <th scope="row">E-mail :</th>
                              <td>{data.email}</td>
                            </tr>
                            <tr>
                              <th scope="row">Location :</th>
                              <td>{data.address}, {data.postcode}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Detail</CardTitle>
                      <div className="table-responsive">
                        <Table className="table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <th scope="row">Status :</th>
                              <td>
                                <span
                                  className={classNames(
                                    "badge badge-pill font-size-11",
                                    statusClasses[data.status?.toLowerCase()]
                                  )}
                                >
                                  {data.status}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Subject :</th>
                              <td>{data.subject}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg={4}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Job Detail</CardTitle>
                    <div className="table-responsive">
                      <div className="table-responsive">
                        <Table className="table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <th scope="row">Job Type :</th>
                              <td>{data.work_type}</td>
                            </tr>
                            <tr>
                              <th scope="row">Priority :</th>
                              <td>
                                <span
                                  className={classNames(
                                    "badge badge-pill font-size-11",
                                    priorityClasses[data.priority?.toLowerCase()]
                                  )}
                                >
                                  {data.priority}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Date :</th>
                              <td>{formatDateTime(data.date)}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Service Detail</CardTitle>
                    <div className="table-responsive">
                      <div className="table-responsive">
                        <Table className="table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <th scope="row">Service :</th>
                              <td>{data.category?.name}</td>
                            </tr>
                            <tr>
                              <th scope="row">Service Type :</th>
                              <td style={{whiteSpace: 'pre-wrap'}}>{data.subcategory?.map(item => item.subcategories?.name).join(',')}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Message</CardTitle>
                  {data.message}
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Images</CardTitle>
                    <Image.PreviewGroup>
                      {data?.image?.map(item => (
                        <Image src={process.env.REACT_APP_BASE_URL + item.url} alt="image" key={item.id} width="20%" />
                      ))}
                    </Image.PreviewGroup>
                </CardBody>
              </Card>
            </Row>
          ) : (
            <Row>
              <Col lg={8}>
                <Card>
                  <CardBody>
                    <Skeleton active paragraph={{ rows: 9 }} />
                  </CardBody>
                </Card>
              </Col>

              <Col lg={4}>
                <Card>
                  <CardBody>
                    <Skeleton active />
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <Skeleton active />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ShowJob)
