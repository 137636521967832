import { useMutation } from '@tanstack/react-query';
import Editor from 'components/Common/Editor';
import { postFormData } from 'helpers/api_helper';
import { showToast } from 'helpers/util';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { Col, Form, Label, Row } from 'reactstrap';
import FieldType from './FieldType';

const SettingPage = ({data, refetch}) => {
  const [errors, setErrors] = useState([])
  const { handleSubmit, setValue, formState: { formErrors }, reset } = useForm();

  useEffect(() => {
    console.log(data)
    if (data) {
      const fields = ['key', 'value', 'type'];
      fields.forEach(field => setValue(field, data[field]));
    }
  }, [data])

  const submitForm = async (value) => {
    var formData = new FormData();
    formData.set('key', value.key);
    formData.set('value', value.value);
    formData.set('type', value.type);
    if (data) {
      formData.set('_method', 'put');
      var res = await postFormData(`settings/${data.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
    }
    return res;
  }

  const { mutate, isLoading } = useMutation(['settingPage'], submitForm, {
    onSuccess: data => {
      if (data.status) {
        showToast(data.message, 'success')
      } else {
        setErrors(data.errors);
      }
      refetch()
    },
    onError: error => {
      console.log('error', error)
      showToast(error, 'error')
    },
  });

  const submit = handleSubmit(values => mutate(values));
  return (
    <Form onSubmit={submit}>
      <Row key={data.id}>
        <Col className="mb-3">
          <Label>{data.display_name}</Label>
          <FieldType data={data} handleChange={setValue} />
        </Col>
      </Row>
      <div className="d-md-flex justify-content-md-end">
        <button type="submit" className="btn btn-primary w-md" disabled={isLoading}>
          {isLoading ? (
            <>
              <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
              Loading...
            </>
          ) : (
            <span>Submit</span>
          )}
        </button>
      </div>
    </Form>
  )
}

export default React.memo(SettingPage);