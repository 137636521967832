// import Confirmation from 'components/Alert/Confirmation';
// import DeleteConfirmation from 'components/Alert/DeleteConfirmation';
import { del, get, post, put } from 'helpers/api_helper';
import { formatDateTime, priorityClasses, showToast, statusClasses } from 'helpers/util';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { Pagination } from 'react-laravel-paginex';
import { Link } from 'react-router-dom';
import CreateProduct from '../CreateBanner';
import { Badge, Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, Spinner, UncontrolledButtonDropdown } from 'reactstrap';
import classNames from 'classnames';
import Select from 'react-select';
import DeleteConfirmation from 'components/Alert/DeleteConfirmation';
import { useQuery } from '@tanstack/react-query';
import { banner } from 'helpers/Api/api';

const TableBanner = () => {
  const [id, setId] = useState(null)
  const [data, setData] = useState(null)
  const [page, setPage] = useState(0)
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null)
  const [showDelete, setShowDelete] = useState(false)
  const { isLoading, refetch } = useQuery(['banners', { page }], () => banner(), {
    onSuccess: (res) => {
      setData(res);
    },
    onError: err => console.log(err),
  });

  const changeStatus = async (status, id) => {
    const res = await put(`/banners/${id}/status`, {
      status
    });
    showToast(res.message, res.status ? 'success' : 'error');
    refetch();
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
    } else{
      setModal(true);
    }
  };

  const deleteAction = async () => {
    const res = await del(`/banners/${id}`);
    setShowDelete(false)
    refetch();
  }

  const selectRow = {
    mode: "checkbox",
    hideSelectColumn: true,
    hideSelectAll: true,
  };

  //pagination customization
  const pageOptions = {
    page: 1,
    sizePerPage: 10,
    pageStartIndex: 1,
    totalSize: data?.total, // replace later with size(patients),
    custom: true,
  };

  const handleSearch = useCallback(debounce(() => {
    refetch()
  }, 800), [])

  const defaultSorted = [
    {
      dataField: "id",
      patient: "desc",
    },
  ];

  const columns = toggleModal => [
    {
      dataField: "images",
      text: "Image",
      sort: true,
      formatter: (cellContent, row) =>
        <img src={process.env.REACT_APP_BASE_URL + row.image} alt="product" width="100" />
      ,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: (cellContent, row) => (
        <Select
          options={[
            { label: 'Active', value: 1 },
            { label: 'Inactive', value: 0 },
          ]}
          style={{ width: '100%' }}
          isLoading={isLoading}
          classNamePrefix="select2-selection"
          defaultValue={{ label: row.status ? 'Active' : 'Inactive', value: row.status }}
          onChange={(e) => changeStatus(e.value, row.id)}
        />
      ),
    },
    {
      dataField: "created_at",
      text: "Created At",
      sort: true,
      formatter: (cellContent, row) => formatDateTime(row.created_at),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // hidden: getRole() == 'Staff' ? true : false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <UncontrolledButtonDropdown>
            <DropdownToggle caret>
              Action
            </DropdownToggle>
              <DropdownMenu>
              <DropdownItem onClick={() => {
                setId(row.id);
                setShowDelete(true);
              }}>
                Delete
              </DropdownItem>
              {/* {(getRole() == 'Admin' || getRole() == 'Staff') && (
              )}
              {(getRole() == 'Admin' || getRole() == 'Staff') && (
                <DropdownItem onClick={() => {
                  setId(row.user.id);
                  setShowDecline(true)
                }}>
                  Decline
                </DropdownItem>
              )}
              {getRole() == 'Admin' && (
                <DropdownItem onClick={() => {
                  setId(row.id);
                  setShowDelete(true);
                }}>
                  Delete
                </DropdownItem>
              )} */}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </>
      ),
    },
  ];
  return (
    <React.Fragment>
      <Row className="mb-2">
        <Col>
          <div className="text-sm-end">
            <Button
              type="button"
              color="primary"
              className="btn-rounded  mb-2 me-2"
              onClick={toggle}
            >
              <i className="mdi mdi-plus me-1" />
              Create Banner
            </Button>
          </div>
        </Col>
      </Row>
      {isLoading ? (
        <Spinner className="ms-2 spinner-loading" color="primary" />
      ) : (
        <Row>
          <Col sm="12">
            {data && (
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={columns()}
                data={data.data}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    data={data.data}
                    columns={columns()}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField="id"
                                responsive
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                noDataIndication={<div className='d-flex justify-content-center'>No data to display</div>}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-md-center mt-30">
                          <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                            <Pagination changePage={(e) => setPage(e.page)} data={data} />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            )}
          </Col>
        </Row>
      )}
      <CreateProduct modal={modal} toggle={toggle} handleCreateModal={toggle} data={modalData}
        refresh={refetch} />
      <DeleteConfirmation showDelete={showDelete} setShowDelete={() => setShowDelete(false)} deleteAction={deleteAction} />
    </React.Fragment>
  )
}

export default TableBanner