import { Button as ButtonAntd, Collapse, Skeleton } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DeleteOutlined } from '@ant-design/icons';
import { Route, useParams, withRouter } from 'react-router-dom';
import Select from 'react-select';
import { Button, Card, CardBody, CardTitle, Col, Container, Form, Input, Label, Row, Table } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { showToast } from 'helpers/util';
import { get, post } from 'helpers/api_helper';
import moment from 'moment';
import { useForm, useFieldArray } from "react-hook-form";
import { debounce, update } from 'lodash';
import Editable from "react-bootstrap-editable"

const CreateQuote = (props) => {
  const { Panel } = Collapse;
  const { id } = useParams();
  const { register, handleSubmit, control, setValue, watch, formState: { formError } } = useForm({
    defaultValues: {
      id,
      due_date: null,
      date: moment().format('YYYY-MM-DD'),
      quote_number: '',
      products: [{ product_id: null, qty: 0, price: 0 }],
      discount: 0,
      notes: '',
    }
  });
  const { fields, append, prepend, remove, swap, update, move, insert } = useFieldArray(
    {
      control,
      name: "products"
    }
  );
  const [loading, setLoading] = useState(false)
  const [loadingPage, setLoadingPage] = useState(false)
  const [data, setData] = useState([])
  const [productData, setProductData] = useState([])
  const [grandTotal, setGrandTotal] = useState('')
  const [quoteNumber, setQuoteNumber] = useState('')
  const products = watch("products");
  const discount = watch("discount");

  const submit = async (value) => {
    try {
      setLoading(true)
      var params = Object.assign(value, {
        user_id: data.user.id,
        address: data.address,
        discount,
        subtotal: grandTotal,
        total: grandTotal - discount,
      })
      const res = await post('/quotes', params);
      if (res.status) {
        props.history.push('/quote')
        showToast(res.message, 'success')
      } else {
        showToast(res.message, 'error')
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      showToast(res.message, 'error')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      let total = value.products.reduce((prev, next) => prev + (next?.price * next?.qty), 0)
      setGrandTotal(total);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleProductInput = useCallback(debounce((val) => {
    try {
      if (val) {
        setLoading(true)
        get('/select-products', {
          params: {
            search: val
          }
        }).then(res => {
          let products = [];
          res.map(item => products.push({ label: item.name, value: item.id, price: item.price }));
          console.log(products)
          setProductData(products)
          setLoading(false)
        })
      }
    } catch (error) {
      showToast('Failed to Load Products Data', 'error')
    }
  }, 300), [])

  const getData = async () => {
    try {
      setLoadingPage(true)
      const res = await get(`/work-orders/${id}`)
      setData(res.work_order)
      const resProduct = await get(`/select-products`)
      setProductData(resProduct.products)
      const resId = await get(`/quotes/latest`)
      setQuoteNumber(resId)
      setValue('quote_number', `QO-${String(parseInt(parseInt(resId.split('-')[1])) + 1).padStart(5, '0')}`);
    } catch (error) {
      console.log(error)
      showToast('Failed to Load Work Order Data', 'error')
    } finally {
      setLoadingPage(false)
    }
  }

  useEffect(() => {
    getData();
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Quote" breadcrumbItem="Create Quote" />
          {/* Render Breadcrumbs */}
          {data && !loadingPage ? (
            <Row>
              <Col>
                <Form onSubmit={handleSubmit((data) => console.log(data))}>
                  <Collapse defaultActiveKey={['2']} ghost bordered >
                    <Panel key="1" header={
                      <div>
                        <h4>Details</h4>
                      </div>}
                    >
                      <Card>
                        <CardBody>
                          <CardTitle>Work Order</CardTitle>
                          <Row className="d-flex align-items-center">
                            <Col md={4}>
                              <Table borderless size="xl">
                                <tr>
                                  <td className="py-2">Name</td>
                                  <td>:</td>
                                  <td>{data.name}</td>
                                </tr>
                                <tr>
                                  <td className="py-2">Phone</td>
                                  <td>:</td>
                                  <td>{data.phone}</td>
                                </tr>
                                <tr>
                                  <td className="py-2">Email</td>
                                  <td>:</td>
                                  <td>{data.email}</td>
                                </tr>
                                <tr>
                                  <td className="py-2">Address</td>
                                  <td>:</td>
                                  <td>{data.address}</td>
                                </tr>
                              </Table>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label>Quote Date</Label>
                                <Input
                                  type="date"
                                  className="form-control"
                                  defaultValue={moment().format('YYYY-MM-DD')}
                                  onInput={(e) => setValue('date', e.target.value)}
                                />
                              </div>
                              <div className="mb-3">
                                <Label>Due Date</Label>
                                <Input
                                  type="date"
                                  className="form-control"
                                  onInput={(e) => setValue('due_date', e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label>Quote Number</Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="quote_number"
                                  placeholder="Enter Quote Number"
                                  {...register('quote_number')}
                                  defaultValue={`QO-${String(parseInt(quoteNumber.split('-')[1]) + 1).padStart(5, '0')}`}
                                  onInput={(e) => setValue('quote_number', e.target.value)}
                                />
                              </div>
                              <div className="mb-3">
                                <Label>Work Order ID</Label>
                                <Input
                                  type="text"
                                  placeholder="Enter Work Order ID"
                                  value={id}
                                  className="form-control"
                                  disabled
                                  onInput={(e) => setValue('quote_id', e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Panel>
                    <hr />
                    <Panel key="2" header={
                      <div>
                        <h4>Billing</h4>
                      </div>}
                    >
                      <Card>
                        <CardBody>
                          <CardTitle>Products</CardTitle>
                          <Table>
                            <thead>
                              <tr>
                                <td>Items</td>
                                <td>Name</td>
                                <td>Qty</td>
                                <td>Price</td>
                                <td>Total</td>
                                <td width={10}></td>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map((item, index) => (
                                <tr style={{ verticalAlign: 'middle' }} key={index}>
                                  <td width="25%">
                                    <Select
                                      options={productData}
                                      onInputChange={(e) => handleProductInput(e)}
                                      isLoading={loading}
                                      name={`products[${index}].product_id`}
                                      classNamePrefix="select2-selection"
                                      defaultValue={`${item.product_id}`}
                                      onChange={(e) => {
                                        setValue(`products[${index}].product_id`, e.value)
                                        setValue(`products[${index}].qty`, 1)
                                        setValue(`products[${index}].price`, e.price)
                                        setValue(`products[${index}].name`, e.label)
                                      }}
                                    />
                                  </td>
                                  <td width="40%">{products[index]?.name}</td>
                                  <td>
                                    <Input
                                      type="number"
                                      className="form-control"
                                      placeholder="Qty"
                                      name={`products[${index}].qty`}
                                      defaultValue={`${item.qty}`}
                                      value={`${products[index]?.qty}`}
                                      onInput={(e) => setValue(`products[${index}].qty`, parseInt(e.target.value))}
                                    />
                                  </td>
                                  <td>
                                    <Input
                                      type="number"
                                      className="form-control"
                                      placeholder="Price"
                                      name={`products[${index}].price`}
                                      // defaultValue={`${products[index].price}`}
                                      value={`${products[index]?.price}`}
                                      onInput={(e) => setValue(`products[${index}].price`, parseInt(e.target.value))}
                                    />
                                  </td>
                                  <td>${products[index] ? parseInt(products[index]?.price * products[index]?.qty, 0) : 0}</td>
                                  <td width={10}><DeleteOutlined onClick={() => remove(index)} role="button" /></td>
                                </tr>
                              ))}
                            </tbody>
                            <tfoot>
                              <tr colSpan={5}>
                                <td colSpan={5} onClick={() => insert(2)} style={{ padding: 0 }}>
                                  <ButtonAntd type="text" block style={{ color: '#1890ff', height: 50 }}>+ Add an Item</ButtonAntd>
                                </td>
                              </tr>
                            </tfoot>
                          </Table>
                          <Table responsive borderless className="float-end text-end" style={{ width: '40%', textAlignLast: 'center' }}>
                            <tr>
                              <th>Subtotal</th>
                              <td>${grandTotal ? grandTotal : 0}</td>
                            </tr>
                            <tr>
                              <th>
                                <Editable
                                  alwaysEditing={false}
                                  disabled={false}
                                  editText="Discount"
                                  id={null}
                                  isValueClickable={false}
                                  mode="inline"
                                  showText={false}
                                  type="textfield"
                                  onSubmit={(value) => setValue('discount', value)}
                                  renderConfirmElement={<button type="submit" className="btn btn-success editable-submit btn-sm me-1"><i className="mdi mdi-check"></i></button>}
                                  renderCancelElement={<button type="button" className="btn btn-danger editable-cancel btn-sm"><i className="mdi mdi-close"></i></button>}
                                />
                              </th>
                              <td>${discount}</td>
                            </tr>
                            <tr>
                              <th>Total</th>
                              <td>${grandTotal - discount || 0}</td>
                            </tr>
                          </Table>

                          <Label htmlFor="notes">Notes</Label>
                          <textarea
                            type="text"
                            className="form-control"
                            placeholder="Enter Notes"
                            id="notes"
                            rows={5}
                            onInput={(e) => setValue('notes', e.target.value)}
                          />
                        </CardBody>
                      </Card>
                    </Panel>
                    <hr />
                    <Panel key="3" header={
                      <div>
                        <h4>Advanced</h4>
                      </div>}
                    >
                      <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Architecto, eligendi!</p>
                    </Panel>
                    <hr />
                  </Collapse>
                  <Button color="primary" className="mb-3 float-end" type="submit" onClick={handleSubmit(submit)}>Submit</Button>
                </Form>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Skeleton active paragraph={{ rows: 9 }} />
                  </CardBody>
                </Card>
              </Col>

              <Col>
                <Card>
                  <CardBody>
                    <Skeleton active paragraph={{ rows: 9 }} />
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <Skeleton active paragraph={{ rows: 9 }} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(CreateQuote)
