const { post, get, put } = require("helpers/api_helper");

const login = data => post('login', data)
const contractor = data => get('contractors', { params: data })
const createContractor = data => post('contractors', data)
const updateContractor = data => put(`contractors/${data.id}`, data)
const admin = data => get('admin', { params: data })
const createAdmin = data => post('admin', data)
const updateAdmin = data => put(`admin/${data.id}`, data)
const ticket = data => get('tickets', { params: data })
const ticketDetail = id => get(`tickets/${id}`)
const replyTicket = (data, id) => post('reply-ticket', {ticket_id: id, ...data})
const banner = data => get('banners', { params: data })
const createBanner = data => post('banners', { params: data })
const updateBanner = data => put(`banners/${data.id}`, data)
const setting = data => get('settings', { params: data })

export {
  login,
  contractor,
  createContractor,
  updateContractor,
  admin,
  createAdmin,
  updateAdmin,
  ticket,
  ticketDetail,
  replyTicket,
  banner,
  createBanner,
  updateBanner,
  setting,
}