import React, { useEffect, useState } from "react"
import { Image, Skeleton } from 'antd';
import { Link, useParams, withRouter } from "react-router-dom"
import classNames from "classnames"
import { Button, Card, CardBody, CardTitle, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, InputGroup, Row, Table, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { get } from "helpers/api_helper"
import { formatDateTime, priorityClasses, statusClasses } from "helpers/util"
import { useQuery } from "@tanstack/react-query";
import { ticketDetail } from "helpers/Api/api";
import PerfectScrollbar from "react-perfect-scrollbar";
import { map } from "lodash";
import moment from "moment";
import Comment from "./Component/Comment";

const ShowTicket = props => {
  let { id } = useParams();
  const [data, setData] = useState([])
  const { isLoading, refetch } = useQuery(['ticket'], () => ticketDetail(id), {
    onSuccess: (res) => {
      setData(res);
    },
    onError: err => console.log(err)
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Tasks" breadcrumbItem="Ticket Detail" />
          {data || isLoading ? (
            <Row>
              <Col lg={8}>
                <Row>
                  <Col md={6}>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">Ticket Detail</CardTitle>
                        <div className="table-responsive">
                          <div className="table-responsive">
                            <Table className="table-nowrap mb-0">
                              <tbody>
                                <tr>
                                  <th scope="row">Priority :</th>
                                  <td>
                                    <span
                                      className={classNames(
                                        "badge badge-pill font-size-11",
                                        priorityClasses[data.priority?.toLowerCase()]
                                      )}
                                    >
                                      {data.priority}
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Status :</th>
                                  <td>
                                    <span
                                      className={classNames(
                                        "badge badge-pill font-size-11",
                                        statusClasses[data.status?.toLowerCase()]
                                      )}
                                    >
                                      {data.status}
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Created At :</th>
                                  <td>{formatDateTime(data.created_at)}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col md={6}>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">Service Detail</CardTitle>
                        <div className="table-responsive">
                          <div className="table-responsive">
                            <Table className="table-nowrap mb-0">
                              <tbody>
                                <tr>
                                  <th scope="row">Products :</th>
                                  <td style={{ whiteSpace: 'pre-wrap' }}>{data?.invoice?.products?.map(({ product }) => product.name).join(',')}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Service :</th>
                                  <td style={{ whiteSpace: 'pre-wrap' }}>{data?.invoice?.products?.map(({ product }) => product.category.name).join(',')}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Service Type :</th>
                                  <td style={{ whiteSpace: 'pre-wrap' }}>{data?.invoice?.products?.map(({ product }) => product.subcategories.map(({ name }) => name)).join(',')}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                {/* <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Images</CardTitle>
                    <Image.PreviewGroup>
                      {data?.image?.map(item => (
                        <Image src={process.env.REACT_APP_BASE_URL + item.image} alt="image" key={item.id} width="20%" />
                      ))}
                    </Image.PreviewGroup>
                  </CardBody>
                </Card> */}
              </Col>

              <Col lg={4}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">User Detail</CardTitle>
                    <div className="table-responsive">
                      <div className="table-responsive">
                        <Table className="table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <th scope="row">Name :</th>
                              <td style={{ whiteSpace: 'pre-wrap' }}>{data?.user?.name}</td>
                            </tr>
                            <tr>
                              <th scope="row">Address :</th>
                              <td style={{ whiteSpace: 'pre-wrap' }}>{data?.user?.address}</td>
                            </tr>
                            <tr>
                              <th scope="row">Email :</th>
                              <td style={{ whiteSpace: 'pre-wrap' }}>{data?.user?.email}</td>
                            </tr>
                            <tr>
                              <th scope="row">Phone :</th>
                              <td style={{ whiteSpace: 'pre-wrap' }}>{data?.user?.phone}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Comment data={data} refresh={refetch} />

            </Row>
          ) : (
            <Row>
              <Col lg={8}>
                <Card>
                  <CardBody>
                    <Skeleton active paragraph={{ rows: 9 }} />
                  </CardBody>
                </Card>
              </Col>

              <Col lg={4}>
                <Card>
                  <CardBody>
                    <Skeleton active />
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <Skeleton active />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {/* <TicketReply modal={modal} toggle={toggle} handleCreateModal={toggle} data={modalData}
        refresh={refetch} /> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default React.memo(withRouter(ShowTicket))
